import React from 'react'
import styles from '../scss/overall.module.scss';
import { ReactComponent as Youtube } from '../assets/icons/icons8_YouTube_2.svg';
import { ReactComponent as Instagram } from '../assets/icons/icons8_Instagram.svg';
import { ReactComponent as Facebook } from '../assets/icons/icons8_facebook.svg';
import { ReactComponent as Twitter } from '../assets/icons/icons8_twitter_squared.svg';

const WhiteSocial = () => {
  return (
    <div className={styles.socials2}>
      <a href='https://www.youtube.com/channel/UCV0oXB6NVvCr1jjCsejSntw'>
        <Youtube className={styles.social} />
      </a>
      <a href='https://www.instagram.com/wosamarena/' target='blank' >
        <Instagram className={styles.social} />
      </a>
      <a href='https://www.facebook.com/wosam.arena.7/' target='blank' >
        <Facebook className={styles.social} />
      </a>
      <a href='https://www.twitter.com/wosamarena/' target='blank' >
        <Twitter className={styles.social} />
      </a>
    </div>
  )
}

export default WhiteSocial