import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import styles from '../scss/overall.module.scss';

import { homeSlide } from '../constants';

import './secondCard.css';

const properties = {
  prevArrow: <div className='arrLeft'>
    <FaChevronLeft className='icon' />
  </div>,
  nextArrow: <div className='arrRight'>
    <FaChevronRight className='icon' />
  </div>,
  transitionDuration: 500,
};

const SecondCard = () => {
  const navigate = useNavigate();

  return (
    <Fade {...properties}>
      {homeSlide.map(item => (
        <div className={styles.secondCard} key={item.h2}>
          <div className={styles.secondCardContent}>
            <h2>{item.h2}</h2>
            <p>{item.p1}
              <br />  <br />
              {item.p2}
            </p>
            <div>
              <button onClick={() => navigate(item.link)} className={styles.secondaryBtn}>Learn More</button>
            </div>
          </div>
          <img src={item.img} alt="Second Img" className={styles.img} />
        </div>
      ))}
    </Fade>
  );
};

export default SecondCard;