import React, { useState } from 'react';
import styles from '../scss/overall.module.scss';
import welcomeImage from '../assets/images/Hotels Apartments Event Halls Event Centers Cinemas in Ogun state Ago Iwoye.jpg';
import { ReactComponent as Youtube } from '../assets/icons/homeYouTube.svg';
import { Link } from 'react-router-dom';
import Video from './video/Video';
import { homeHeroImage } from '../assets';

const FirstCard = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.firstCard}>
      <div className={styles.firstCardContent}>
        <h1>Welcome to WosAm Arena</h1>
        <p>WosAm Arena is one of the biggest and best hospitality and leisure arena in Ago-Iwoye, Ogun state Nigeria, consisting of our Hotels, Apartments, Lounge, Café and many more.. We assure you your money's worth.</p>
        <div>
          <button onClick={handleClickOpen}><Youtube className={styles.social} />Take a tour</button>
          <Link to='/contact-us' style={{ textDecoration: 'none' }}>
            <button className={styles.secondaryBtn}>Contact Us</button>
          </Link>
        </div>
      </div>
      <img src={homeHeroImage} alt="Hotels Apartments Event Halls Event Centers Cinemas in Ogun state Ago Iwoye" />

      {/* Youtube Modal */}
      <Video open={open} handleClose={handleClose} />
    </div>
  );
};

export default FirstCard;