import React from 'react';
import { Helmet } from 'react-helmet';
import { NewsLetter } from '../../components';

const Newsletter = () => {
  return (
    <>
      <Helmet>
        <title>NewsLetter | WosAm.com</title>
      </Helmet>
      <NewsLetter />
    </>
  );
};

export default Newsletter;