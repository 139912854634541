import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './carousel.css';

export const CarouselItem = ({ img, text, name, styles, bgImg }) => {
  return (
    <div className='carousel-item ci'>
      <div className={styles.reviewContent} style={{ whiteSpace: 'normal' }}>
        <h2>Some of our customers reviews</h2>
        <p><em>{text}</em></p>
        <div className={styles.customers}>
          <h3>{name}</h3>
        </div>
      </div>
      <div className={styles.customerImage}
        style={{
          backgroundImage: { bgImg },
        }}>
        {/* <img src={img} alt="quote" className={styles.quote} /> */}
      </div>
    </div>
  );
};

const ReviewCarousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 2500);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1)
  });

  return (
    <div
      {...handlers}
      className="carousel"
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <div className="inner" style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
      <div className="indicators">
        {React.Children.map(children, (child, index) => {
          return (
            <button className={`${index === activeIndex ? "active" : ""}`}></button>
          );
        })}
      </div>
      <div className='arrows'>
        <div className='left' onClick={() => { updateIndex(activeIndex - 1); }}>
          <FaChevronLeft className='icon' />
        </div>
        <div className='right' onClick={() => { updateIndex(activeIndex + 1); }}>
          <FaChevronRight className='icon' />
        </div>
      </div>
    </div>
  );
};

export default ReviewCarousel;
