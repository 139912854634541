import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import styles from '../scss/overall.module.scss';
import { ReactComponent as Chevron } from '../assets/icons/Icon ionic-ios-arrow-down.svg';
import avatar from '../assets/images/avatar.png';
import avatar2 from '../assets/images/avatar2.png';
import { ReactComponent as Phone } from '../assets/icons/icons8_phone.svg';
import { ReactComponent as AtSign } from '../assets/icons/icons8_At_sign.svg';
import { ourTeamImage } from '../assets';

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID_CAREER;
const USER_ID = process.env.REACT_APP_USERID;

const Career = () => {
  const styles2 = {
    backgroundImage: `url(${ourTeamImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center' 
  }
  const { register, handleSubmit, reset } = useForm();
  const down = useRef(null);

  const scrollDown = () => {
    window.scrollTo({
      top: down.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const onSubmit = (e) => {
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, '#form', USER_ID)
      .then(
        (result) => {
          // console.log(result)
          toast.success("Message Sent Successfully");
        },
        (err) => {
          toast.error("Something went wrong. Try again");
          // console.log(err.text);
        }
      );
    reset();
  };

  const handleError = (errors) => console.log(errors);
  return (
    <div className={styles.careerPage}>
      <Helmet>
        <title>Career | WosAm.com</title>
      </Helmet>
      <header style={styles2}>
        <h1>Work alongside amazing people.</h1>
        <p>We’re looking for passionate, self-motivated and daring individuals to join our team.</p>
        <button onClick={scrollDown}>Join Us</button>
      </header>

      <p>Scroll down</p>

      <section>
        <h2>How we hire our team.</h2>
        <p>We like to keep things as simple as possible so we can get to what’s really important.</p>
        <div className={styles.careerDiv}>
          <div className={styles.one}>
            <h4>01</h4>
            <h3>Application and recruiter screen</h3>
            <p>We like to keep things as simple as possible so we can get to what’s really important - finding out more about you.</p>
          </div>
          <div className={styles.two}>
            <h4>02</h4>
            <h3>First round interview</h3>
            <p>We love connecting with people who believe they’ll make a difference. At this stage, an interview will help us both decide whether the interest is mutual.</p>
          </div>
          <div className={styles.three}>
            <h4>03</h4>
            <h3>Final interview and offer</h3>
            <p>Once you’ve met the team, and we’ve gotten to know each other, it’s time for us to both decide whether we’re a match.</p>
          </div>
        </div>
      </section>

      <div className={styles.jobDiv}>
        <h2>Available Job Openings</h2>
        <div className={styles.jobs}>
          <div className={styles.job} onClick={scrollDown}>
            <div>
              <img src={avatar} alt="avatar" />
              <h3>Manager <span>Ogun State, Nigeria (On-site)</span></h3>
            </div>
            <Chevron className={styles.jobIcon} />
          </div>
          <div className={styles.job} onClick={scrollDown}>
            <div>
              <img src={avatar2} alt="avatar" />
              <h3>Supervisor <span>Ogun State, Nigeria (On-site)</span></h3>
            </div>
            <Chevron className={styles.jobIcon} />
          </div>
          <div className={styles.job} onClick={scrollDown}>
            <div>
              <img src={avatar} alt="avatar" />
              <h3>Projectionist <span>Ogun State, Nigeria (On-site)</span></h3>
            </div>
            <Chevron className={styles.jobIcon} />
          </div>
          <div className={styles.job} onClick={scrollDown}>
            <div>
              <img src={avatar2} alt="avatar" />
              <h3>Accountant <span>Ogun State, Nigeria (On-site)</span></h3>
            </div>
            <Chevron className={styles.jobIcon} />
          </div>
          <div className={styles.job} onClick={scrollDown}>
            <div>
              <img src={avatar} alt="avatar" />
              <h3>HR <span>Ogun State, Nigeria (On-site)</span></h3>
            </div>
            <Chevron className={styles.jobIcon} />
          </div>
        </div>
      </div>

      <div className={styles.careerForm} ref={down}>
        <h2>We love to connect with people who want to make a Difference</h2>
        <p>Feel free to get in touch even if you believe you see a role here that fits your skills. Complete the form with your CV and a few words about why you’d like to join us.</p>
        <div className={styles.formContainer}>
          <h3>Apply to Job Opening</h3>
          <form encType="multipart/form-data" id='form' onSubmit={handleSubmit(onSubmit, handleError)}>
            <select name="job" id="" {...register('job')}>
              <option>Job Opening</option>
              <option value="Manager">Manager (1 needed)</option>
              <option value="Supervisor">Supervisor (1 needed)</option>
              <option value="Door Man">Door Man (2 needed)</option>
              <option value="Cleaner">Cleaner (2 needed)</option>
              <option value="Projectionist">Projectionist (2 needed)</option>
              <option value="Accountant">Accountant (2 needed)</option>
              <option value="Concession/Ticket">Concession/Ticket (3 needed)</option>
              <option value="HR">HR (1 needed)</option>
              <option value="Admin">Admin (1 needed)</option>
              <option value="Procurement/Store keeper">Procurement/Store keeper (1 needed)</option>
            </select>
            <input type="text" placeholder='Full Name' name='name' {...register("name")} />
            <input type="text" placeholder='Email Address' name='email' {...register("email")} />
            <input type="file" placeholder='Upload Resume/CV' name='my_file' {...register("my_file")} />
            <textarea name="coverLetter" id="" cols="30" rows="10" placeholder='Cover Letter' {...register("coverLetter")} />
            <button type="submit" >Send</button>
          </form>
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover={false}
          />
          <div className={styles.contacts}>
            <span><AtSign className={styles.icon} /> info@wosamarena.com</span>
            <span><Phone className={styles.icon} />09164418000, 09164419000, 09153253750</span>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Career;