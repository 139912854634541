import React from 'react';
import { Helmet } from 'react-helmet';
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useForm } from 'react-hook-form';
import styles from '../../scss/overall.module.scss';
import truck from '../../assets/images/truck.png';
import { ReactComponent as Location } from '../../assets/icons/icons8_Location.svg';
import { ReactComponent as Phone } from '../../assets/icons/icons8_phone.svg';
import { ReactComponent as AtSign } from '../../assets/icons/icons8_At_sign.svg';
import photo1 from '../../assets/images/photo1.jpg';
import WhiteSocial from '../../components/WhiteSocial';
import photo2 from '../../assets/images/Apartments in Ogun state.png';
import photo3 from '../../assets/images/Cinema in Ogun state.png';

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID_WOSAMONTHEMOVE;
const USER_ID = process.env.REACT_APP_USERID;

const Wosamonthemove = () => {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (e) => {
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, '#form', USER_ID)
      .then(
        (result) => {
          console.log(result)
          toast.success("Message Sent Successfully");
        },
        (err) => {
          toast.error("Something went wrong. Try again");
          // console.log(err.text);
        }
      );
    reset();
  };

  const handleError = (errors) => console.log(errors);

  return (
    <div className={styles.wosamonthemove}>
      <Helmet>
        <title>Wosam on the move | WosAm.com</title>
      </Helmet>
      <header>
        <div className={styles.truck}>
          <h3>- WOSAM ON THE MOVE -</h3>
          <img src={truck} alt="truck" />
        </div>
        <form id='form' onSubmit={handleSubmit(onSubmit, handleError)}>
          <h2>Car rental Service <span>(Wosamonthemove)</span></h2>
          <div className={styles.formInput}>
            <div className={styles.location}>
              <input type="text" placeholder='Pick Up Location' className={styles.locationInput} name='pickupLocation' {...register("pickupLocation")} />
              <Location className={styles.locationIcon} />
            </div>
            <input type="datetime-local" name='pickupTime' {...register("pickupTime")} />
          </div>
          <div className={styles.formInput}>
            <div className={styles.location}>
              <input type="text" placeholder='Drop Off Location' className={styles.locationInput} name='dropoffLocation' {...register("dropoffLocation")} />
              <Location className={styles.locationIcon} />
            </div>
            <input type="datetime-local" name='dropoffTime' {...register("dropoffTime")} />
          </div>
          <input type="text" placeholder='Enter Name' name='name' {...register("name")} />
          <input type="email" placeholder='Enter Email Address' name='email' {...register("email")} />
          <input type="number" placeholder='Enter Phone Number' name='number' {...register("number")} />
          <button>Confirm Booking</button>
        </form>
        <WhiteSocial />
      </header>
      <p>Scroll down</p>
      <div className={styles.photoBooth}>
        <h1>Photo Booth</h1>
        <p>We offer the best of technologies to make sure your next event is a hit. Photo booth, The 360 booth, Slow Motion, Magic Mirror, Photo Station and much more</p>
        <button>Book This Service</button>
      </div>
      <div className={styles.photoBooth2}>
        <div>
          <img src={photo1} alt="img" />
          <h3>THE 360 BOOTH</h3>
          <p>We offer the best of technologies to make sure your next event is a hit. Photo booth, The 360 booth.</p>
        </div>
        <div>
          <img src={photo2} alt="img" />
          <h3>THE ARRAY</h3>
          <p>We offer the best of technologies to make sure your next event is a hit. Photo booth, The 360 booth.</p>
        </div>
        <div>
          <img src={photo3} alt="img" />
          <h3>SLOW MOTION</h3>
          <p>We offer the best of technologies to make sure your next event is a hit. Photo booth, The 360 booth.</p>
        </div>
        <div>
          <img src={photo1} alt="img" />
          <h3>MAGIC</h3>
          <p>We offer the best of technologies to make sure your next event is a hit. Photo booth, The 360 booth.</p>
        </div>
        <div>
          <img src={photo2} alt="img" />
          <h3>SUPER SOCIAL</h3>
          <p>We offer the best of technologies to make sure your next event is a hit. Photo booth, The 360 booth.</p>
        </div>
        <div>
          <img src={photo3} alt="img" />
          <h3>THE ENCLOSED</h3>
          <p>We offer the best of technologies to make sure your next event is a hit. Photo booth, The 360 booth.</p>
        </div>
        <div>
          <img src={photo1} alt="img" />
          <h3>PHOTO STATION</h3>
          <p>We offer the best of technologies to make sure your next event is a hit. Photo booth, The 360 booth.</p>
        </div>
        <div>
          <img src={photo2} alt="img" />
          <h3>GREEN SCREEN</h3>
          <p>We offer the best of technologies to make sure your next event is a hit. Photo booth, The 360 booth.</p>
        </div>
        <div>
          <img src={photo3} alt="img" />
          <h3>THE ROAMER</h3>
          <p>We offer the best of technologies to make sure your next event is a hit. Photo booth, The 360 booth.</p>
        </div>
      </div>
      <div className={styles.requestQuote2}>
        <div className={styles.formContainer}>
          <h3>Request a quote</h3>
          <form onSubmit={handleSubmit(onSubmit, handleError)}>
            <div>
              <input type="text" placeholder='Name' name='name' {...register("name")} />
              <input type="text" placeholder='Phone Number' name='phone' {...register("phone")} />
            </div>
            <input type="text" placeholder='Email Address' {...register("email")} />
            <textarea name="" id="" cols="30" rows="10" placeholder='Tell us how we can help you...' {...register("Message")} />
            <button type="submit">Send</button>
          </form>
          <div className={styles.contacts}>
            <span><AtSign className={styles.icon} /> info@wosamarena.com</span>
            <span><Phone className={styles.icon} />09164418000, 09164419000, 09153253750</span>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Wosamonthemove;