import React from 'react';
import { Helmet } from 'react-helmet';
import { Contact } from '../components';

const ContactUsPage = () => {
  return (
    <div>
      <Helmet>
        <title>Contact Us | WosAm.com</title>
      </Helmet>
      <Contact />
    </div>
  );
};

export default ContactUsPage;
