import React from 'react';
import { Helmet } from 'react-helmet';
import styles from '../../scss/overall.module.scss';
import Review from '../../components/Review';
import WhiteSocial from '../../components/WhiteSocial';
import { ReactComponent as Capacity } from '../../assets/icons/icons8_museum_1.svg';
import { ReactComponent as Linens } from '../../assets/icons/icons8_blankie.svg';
import { ReactComponent as Bartenders } from '../../assets/icons/icons8_man_in_a_tuxedo_1.svg';
import { ReactComponent as Chair } from '../../assets/icons/icons8_chair.svg';
import { ReactComponent as Management } from '../../assets/icons/icons8_management.svg';
import { ReactComponent as Security } from '../../assets/icons/icons8_security_guard_1.svg';
import card from '../../assets/images/MS Lounge Wosam Arena.png';

const WosamNightClub = () => {
  return (
    <div className={styles.wosamNightClub}>
      <Helmet>
        <title>Wosam Night Club | WosAm.com</title>
      </Helmet>
      <header>
        <h3>- WOSAM NIGHT CLUB -</h3>
        <h1>The Party Never Stops At WosAm Arena.</h1>
        <p>At WosAm arena, the party never stops; an exquisite embodiment of a luxury fulfilled lifestyle our plan is to develop a superior nightlife experience. we have come to keep on setting the benchmark for nightlife fun here in ago iwoye ogun state.</p>
        <button>View Gallery</button>
        <WhiteSocial />
      </header>
      <p>Scroll down</p>
      <h2>Our Night Club</h2>
      <div className={styles.firstCard}>
        <div className={styles.firstCardContent}>
          <h2>The Night Club</h2>
          {/* <h3>₦800,000/day</h3> */}
          <p>WosAm Nightclub is an exquisite embodiment of a luxury fulfilled lifestyle. Our plan is to develop a superior nightlife experience. We have come to keep on setting the benchmark for the nightlife.</p>
          <button>View Gallery</button>
          <div className={styles.newSpaces}>
            <div className={styles.newSpace}>
              <Capacity className={styles.spaceIcon} />
              <div>
                <h3>Capacity</h3>
                <p>200</p>
              </div>
            </div>
            <div className={styles.newSpace}>
              <Linens className={styles.spaceIcon} />
              <div>
                <h3>Linens</h3>
                <p>Available</p>
              </div>
            </div>
            <div className={styles.newSpace}>
              <Bartenders className={styles.spaceIcon} />
              <div>
                <h3>Bartenders</h3>
                <p>All Inclusive</p>
              </div>
            </div>
            <div className={styles.newSpace}>
              <Chair className={styles.spaceIcon} />
              <div>
                <h3>Table & Chair</h3>
                <p>Setup & Clean</p>
              </div>
            </div>
            <div className={styles.newSpace}>
              <Management className={styles.spaceIcon} />
              <div>
                <h3>Management</h3>
                <p>On-Site</p>
              </div>
            </div>
            <div className={styles.newSpace}>
              <Security className={styles.spaceIcon} />
              <div>
                <h3>Security Guards</h3>
                <p>Indoor & Outdoor</p>
              </div>
            </div>

          </div>
        </div>
        <img src={card} alt="welcome Img" />
      </div>
      <Review />
    </div>
  );
};

export default WosamNightClub;