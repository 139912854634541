import React from 'react';
import { Helmet } from 'react-helmet';
import styles from '../../scss/overall.module.scss';
import { ReactComponent as Corporate } from '../../assets/icons/icons8_tie.svg';
import wosamCorporate from '../../assets/images/wosam-arena-events-centre-in-ago-iwoye-ogun-state-book-event-hall-scaled_Image_minify.png';
import { corporateEventImage, corporateImage2 } from '../../assets';

const CorporateEvent = () => {

  const styles2 = {
    backgroundImage: `url(${corporateEventImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center' 
  }

  return (
    <div className={styles.corporateEvent}>
      <Helmet>
        <title>Corporate Event | WosAm.com</title>
      </Helmet>
      <div style={styles2} className={styles.corporate}>
        <h2>Corporate Events</h2>
        <h4>- Exceptional Experiences -</h4>
        <p>Every event at WosAm Arena is a fully customized experience tailored to your specific vision and needs for your event. Businesses from the entire Western Nigeria have hosted hundreds of events like these at our venue.</p>
        <button>Book our Hall</button>
      </div>
      <div className={styles.subHeader}>
        <h2>Corporate Events Like</h2>
        <div className={styles.corporateTypes}>
          <div className={styles.corporateType}>
            <Corporate className={styles.corporateIcon} />
            <p>BUSINESS MEETINGS and LUNCHEONS</p>
          </div>
          <div className={styles.corporateType}>
            <Corporate className={styles.corporateIcon} />
            <p>NON-PROFIT FUNDRAISING GALAS</p>
          </div>
          <div className={styles.corporateType}>
            <Corporate className={styles.corporateIcon} />
            <p>SEMINARS & WORKSHOPS</p>
          </div>
          <div className={styles.corporateType}>
            <Corporate className={styles.corporateIcon} />
            <p>CORPORATE HOLIDAY PARTIES</p>
          </div>
          <div className={styles.corporateType}>
            <Corporate className={styles.corporateIcon} />
            <p>CORPORATE CONFERENCES</p>
          </div>
          <div className={styles.corporateType}>
            <Corporate className={styles.corporateIcon} />
            <p>BUSINESS EXPO & INDUSTRY TASTINGS</p>
          </div>
          <div className={styles.corporateType}>
            <Corporate className={styles.corporateIcon} />
            <p>CORPORATE AWARD CEREMONIES & GALAS</p>
          </div>
          <div className={styles.corporateType}>
            <Corporate className={styles.corporateIcon} />
            <p>BUSINESS PRODUCT LAUNCH</p>
          </div>
        </div>
      </div>
      <div className={styles.secondCard}>
        <div className={styles.secondCardContent}>
          <span></span>
          <h2>Corporate Events Venue</h2>
          <p>Corporate businesses, universities and other educational institutions, non-profit organizations, and government agencies choose our venue because we offer 42,000 square feet of rental space with three different rooms to choose from and can easily accommodate 100 to 4,000 guests for small, intimate affairs to grand galas to multi-day, large-scale events.</p>
          <div>
            <button className={styles.secondaryBtn}>Learn More</button>
          </div>
        </div>
        <img src={corporateImage2} alt="Img" />
      </div>
    </div>
  );
};

export default CorporateEvent;
