import React, { useState } from 'react';
import styles from '../scss/overall.module.scss';
// import logo from '../assets/logo/Wosam Header Logo New.png';
import { FaChevronDown } from 'react-icons/fa';
import { RiCloseLine, RiMenu3Line } from 'react-icons/ri';
import { Link, NavLink } from 'react-router-dom';
import { aboutLinks, eventsLinks, facilitiesLinks, mediaLinks } from '../constants';
import { wosamArenaLogo } from '../assets';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleClick = () => setToggleMenu(!toggleMenu);
  const closeMobileMenu = () => setToggleMenu(false);

  return (
    <nav>
      <Link to='/'><img src={wosamArenaLogo} className={styles.logo} alt='wosAmArena logo' /></Link>
      <div className={styles.toggle} onClick={handleClick}>
        {toggleMenu ? <RiCloseLine size={20} className={styles.toggleIcon} /> : <RiMenu3Line size={20} className={styles.toggleIcon} />}
      </div>
      {toggleMenu ?
        <div className={styles.toggleRight}>
          <ul className={styles.togglelinks}>

            {/* Initial links */}
            <li className={styles.togglelink}><NavLink style={({ isActive }) => { return { color: isActive ? '#ffb14a' : '#f5f5f5' }; }} to="/" onClick={closeMobileMenu} >Home</NavLink></li>

            {/* Dropdown links */}
            <li
              className={styles.togglelink}
            >
              <span>About<FaChevronDown size={12} className={styles.toggleicon} /></span>
              <ul>
                {aboutLinks.map(link => (
                  <NavLink
                    style={({ isActive }) => { return { color: isActive ? '#ffb14a' : undefined }; }}
                    to={link.path}
                    key={link.id}
                    onClick={closeMobileMenu}
                  >
                    {link.title}
                  </NavLink>
                ))}
              </ul>
            </li>

            <li
              className={styles.togglelink}
            >
              <span>Events<FaChevronDown size={12} className={styles.toggleicon} /></span>
              <ul>
                {eventsLinks.map(link => (
                  <NavLink
                    style={({ isActive }) => { return { color: isActive ? '#ffb14a' : undefined }; }}
                    to={link.path}
                    key={link.id}
                    onClick={closeMobileMenu}
                  >
                    {link.title}
                  </NavLink>
                ))}
              </ul>
            </li>

            <li
              className={styles.togglelink}
            >
              <span>Facilities and Services<FaChevronDown size={12} className={styles.toggleicon} /></span>
              <ul className={styles.togglelong}>
                {facilitiesLinks.map(link => (
                  <NavLink
                    style={({ isActive }) => { return { color: isActive ? '#ffb14a' : undefined }; }}
                    to={link.path}
                    key={link.id}
                    target='_blank'
                    onClick={closeMobileMenu}
                  >
                    {link.title}
                  </NavLink>
                ))}
              </ul>
            </li>

            <li
              className={styles.togglelink}
            >
              <span>Media<FaChevronDown size={12} className={styles.toggleicon} /></span>
              <ul>
                {mediaLinks.map(link => (
                  <NavLink
                    style={({ isActive }) => { return { color: isActive ? '#ffb14a' : undefined }; }}
                    to={link.path}
                    key={link.id}
                    onClick={closeMobileMenu}
                  >
                    {link.title}
                  </NavLink>
                ))}
              </ul>
            </li>

            {/* Other Links */}
            <li className={styles.togglelink}><NavLink style={({ isActive }) => { return { color: isActive ? '#ffb14a' : '#f5f5f5' }; }} to="/career" onClick={closeMobileMenu} className={styles.togglehover}>Career</NavLink></li>
            <li className={styles.togglelink}><NavLink style={({ isActive }) => { return { color: isActive ? '#ffb14a' : '#f5f5f5' }; }} to="/contact-us" onClick={closeMobileMenu} className={styles.togglehover}>Contact</NavLink></li>
          </ul>
        </div> :
        <div className={styles.right}>
          <ul className={styles.links}>

            {/* Initial links */}
            <li className={styles.link}><NavLink to="/" style={({ isActive }) => { return { color: isActive ? '#ffb14a' : '#f5f5f5' }; }}>Home</NavLink></li>

            {/* Dropdown links */}
            <li
              className={styles.link}
            // className='flex'
            >
              <span className='flex items-center'>About<FaChevronDown size={12} className={styles.icon} /></span>
              <ul>
                {aboutLinks.map(link => (
                  <NavLink
                    style={({ isActive }) => { return { color: isActive ? '#ffb14a' : undefined }; }}
                    to={link.path}
                    key={link.id}
                    onClick={closeMobileMenu}
                  >
                    {link.title}
                  </NavLink>
                ))}
              </ul>
            </li>

            <li
              className={styles.link}
            >
              <span className='flex items-center'>Events<FaChevronDown size={12} className={styles.icon} /></span>
              <ul>
                {eventsLinks.map(link => (
                  <NavLink
                    style={({ isActive }) => { return { color: isActive ? '#ffb14a' : undefined }; }}
                    to={link.path}
                    key={link.id}
                    onClick={closeMobileMenu}
                  >
                    {link.title}
                  </NavLink>
                ))}
              </ul>
            </li>

            <li
              className={styles.link}
            >
              <span className='flex items-center'>Facilities and Services<FaChevronDown size={12} className={styles.icon} /></span>
              <ul className={styles.long}>
                {facilitiesLinks.map(link => (
                  <NavLink
                    style={({ isActive }) => { return { color: isActive ? '#ffb14a' : undefined }; }}
                    to={link.path}
                    key={link.id}
                    target='_blank'
                    onClick={closeMobileMenu}
                  >
                    {link.title}
                  </NavLink>
                ))}
              </ul>
            </li>

            <li
              className={styles.link}
            >
              <span className='flex items-center'>Media<FaChevronDown size={12} className={styles.icon} /></span>
              <ul>
                {mediaLinks.map(link => (
                  <NavLink
                    style={({ isActive }) => { return { color: isActive ? '#ffb14a' : undefined }; }}
                    to={link.path}
                    key={link.id}
                    onClick={closeMobileMenu}
                  >
                    {link.title}
                  </NavLink>
                ))}
              </ul>
            </li>

            {/* Other Links */}
            <li className={styles.link}><NavLink to="/career" style={({ isActive }) => { return { color: isActive ? '#ffb14a' : '#f5f5f5' }; }} >Career</NavLink></li>
            <li className={styles.link}><NavLink to="/contact-us" style={({ isActive }) => { return { color: isActive ? '#ffb14a' : '#f5f5f5' }; }} >Contact</NavLink></li>
          </ul>
        </div>}
    </nav>
  );
};

export default Navbar;