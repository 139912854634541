import React from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useForm } from 'react-hook-form';
import styles from '../scss/overall.module.scss';
import metroMail from '../assets/images/metroMail.png';

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID_NEWSLETTER;
const USER_ID = process.env.REACT_APP_USERID;

const NewsLetter = () => {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (e) => {
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, '#form', USER_ID)
      .then(
        (result) => {
          console.log(result);
          toast.success("Message Sent Successfully");
        },
        (err) => {
          toast.error("Something went wrong. Try again");
          // console.log(err.text);
        }
      );
    reset();
  };

  const handleError = (errors) => console.log(errors);

  return (
    <div className={styles.newsLetter}>
      <img src={metroMail} alt="metroMail" />
      <h1>Subscribe to Our Newsletter</h1>
      <span>Join our subscriber list to get our latest news, updates, and special offers delivered directly in your inbox.</span>
      <form id='form' className={styles.subscribe} onSubmit={handleSubmit(onSubmit, handleError)}>
        <input type="email" className={styles.input} placeholder='Enter your Email' name='email' {...register("email")} />
        <input type='submit' value='Subscribe' className={styles.submit} />
      </form>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />;
    </div>
  );
};

export default NewsLetter;
