import React from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useForm } from 'react-hook-form';
import styles from '../../scss/overall.module.scss';
import { RequestQuote } from '../../components';
import { ReactComponent as Youtube } from '../../assets/icons/homeYouTube.svg';
import { ReactComponent as Phone } from '../../assets/icons/icons8_phone.svg';
import { ReactComponent as AtSign } from '../../assets/icons/icons8_At_sign.svg';
import { slideImg } from '../../constants';
import SlideshowCarousel, { CarouselItem } from '../../components/carousels/SlideshowCarousel';

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID_REQUESTAQUOTE;
const USER_ID = process.env.REACT_APP_USERID;

const GalleryPage = () => {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (e) => {
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, '#form', USER_ID)
      .then(
        (result) => {
          // console.log(result)
          toast.success("Message Sent Successfully");
        },
        (err) => {
          toast.error("Something went wrong. Try again");
          // console.log(err.text);
        }
      );
    reset();
  };

  const handleError = (errors) => console.log(errors);

  return (
    <div className={styles.galleryPage}>
      <header>
        <span></span>
        <h2>Gallery</h2>
        <p>A picture is worth more than a thousand words</p>
      </header>
      <div className={styles.buju}>
        <h3>Experience the fun and see it for yourself.</h3>
        <button><Youtube className={styles.social} />Watch Video</button>
      </div>
      <div className={styles.slideshow}>
        <h3>Slideshow</h3>
        {/* <div className={styles.slides}>
          <div className={styles.slide1}></div>
          <div className={styles.slide2}></div>
          <div className={styles.slide3}></div>
        </div> */}
        <div className={styles.slidesSmallscreen}>
          <SlideshowCarousel>
            {slideImg.map((item, index) => (
              <CarouselItem img={item.img} key={item.img + index} />
            ))}
          </SlideshowCarousel>
        </div>
      </div>
      <div className={styles.pictures}>
        <h3>More Pictures</h3>
        <div className={styles.slides}>
          <div className={styles.slide1}></div>
          <div className={styles.slide2}>
            <div className={styles.slide1}></div>
            <div className={styles.slide2}></div>
          </div>
          <div className={styles.slide3}></div>
        </div>
      </div>
      <RequestQuote />
      <div className={styles.requestQuote2}>
        <div className={styles.formContainer}>
          <h3>Request a quote</h3>
          <form id='form' onSubmit={handleSubmit(onSubmit, handleError)}>
            <div>
              <input type="text" placeholder='Name' name='name' {...register("name")} />
              <input type="text" placeholder='Email' name='email' {...register("email")} />
            </div>
            <div>
              <input name='guests' type="text" placeholder='Estimated Guests' {...register("guests")} />
              <input name='budget' type="text" placeholder='Budget' {...register("budget")} />
            </div>
            <textarea name="event" id="" cols="30" rows="10" placeholder='Tell us about your event...' {...register("event")} />
            <button type="submit">Send</button>
          </form>
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover={false}
          />
          <div className={styles.contacts}>
            <span><AtSign className={styles.icon} /> info@wosamarena.com</span>
            <span><Phone className={styles.icon} />09164418000, 09164419000, 09153253750</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryPage;