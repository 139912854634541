import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import styles from '../../scss/overall.module.scss';
// import buju3 from '../../assets/images/buju3.jpg';
import picture1 from '../../assets/images/buju4.jpg';
import picture2 from '../../assets/images/Wosam Apartment.png';
import picture3 from '../../assets/images/Wosam Concert space area.png';
import picture4 from '../../assets/images/Traditional wedding in wosam.png';
import picture5 from '../../assets/images/picture5.png';
import picture6 from '../../assets/images/picture6.png';
import picture7 from '../../assets/images/wedding in wosam.png';
import picture8 from '../../assets/images/Latest news in ogun state.png';
import picture9 from '../../assets/images/Ogun state news.png';
import falomo from '../../assets/images/falomo.png';

const LatestNews = () => {
  const navigate = useNavigate()

  return (
    <div className={styles.latestNewsPage}>
      <Helmet>
        <title>Latest News | WosAm.com</title>
      </Helmet>
      <header>
        <span></span>
        <h2>Latest News</h2>
      </header>

      <div className={styles.buju2}>
        <h3>Experience the fun and see it for yourself.</h3>
      </div>

      <p>Scroll down</p>

      <div className={styles.latestDiv}>
        <img src={falomo} alt="img" />
        <section>
          <h3>The Maiden Edition of Folumo</h3>
          <p>More on How Wosam Arena End of the year party went More on How Wosam Arena End of the year party went More on How Wosam Arena End of the year party went</p>
          <button onClick={() => navigate('/media/latest-news/1')}>
            Read now
          </button>
        </section>
      </div>

      <div className={styles.news}>
        <div>
          <img src={picture1} alt="Buju's pic" />
          <h4>More on How Wosam Arena End of the year party went</h4>
          <p>More on How Wosam Arena End of the year party went More on ...</p>
          <button>Read now</button>
        </div>
        <div>
          <img src={picture2} alt="Buju's pic" />
          <h4>More on How Wosam Arena End of the year party went</h4>
          <p>More on How Wosam Arena End of the year party went More on ...</p>
          <button>Read now</button>
        </div>
        <div>
          <img src={picture3} alt="Buju's pic" />
          <h4>More on How Wosam Arena End of the year party went</h4>
          <p>More on How Wosam Arena End of the year party went More on ...</p>
          <button>Read now</button>
        </div>
        <div>
          <img src={picture4} alt="Buju's pic" />
          <h4>Pictures from #SAFA'22 that we cant get enough of.</h4>
          <p>More on How Wosam Arena End of the year party went More on ...</p>
          <button>Read now</button>
        </div>
        <div>
          <img src={picture5} alt="Buju's pic" />
          <h4>More on How Wosam Arena End of the year party went</h4>
          <p>More on How Wosam Arena End of the year party went More on ...</p>
          <button>Read now</button>
        </div>
        <div>
          <img src={picture6} alt="Buju's pic" />
          <h4>More on How Wosam Arena End of the year party went</h4>
          <p>More on How Wosam Arena End of the year party went More on ...</p>
          <button>Read now</button>
        </div>
        <div>
          <img src={picture7} alt="Buju's pic" />
          <h4>Pictures from #ZABA'21 that we cant get enough of.</h4>
          <p>More on How Wosam Arena End of the year party went More on ...</p>
          <button>Read now</button>
        </div>
        <div>
          <img src={picture8} alt="Buju's pic" />
          <h4>Pictures from #BIBA'22 that we cant get enough of.</h4>
          <p>More on How Wosam Arena End of the year party went More on ...</p>
          <button>Read now</button>
        </div>
        <div>
          <img src={picture9} alt="Buju's pic" />
          <h4>Pictures from #TISA'22 that we cant get enough of.</h4>
          <p>More on How Wosam Arena End of the year party went More on ...</p>
          <button>Read now</button>
        </div>
      </div>
    </div>
  );
};

export default LatestNews;