import React from 'react';
import styles from '../scss/overall.module.scss';
import { Link } from 'react-router-dom';

const RequestQuote = () => {
    return (
        <div className={styles.requestQuote}>
            <h2>REQUEST A QUOTE</h2>
            <p>Promoting Memorable is what we want for you with out the necessities of breaking the bank! Just request for a quote for any of our services you need and your budget will genuinely be observed</p>
            <div className={styles.cards}>
                <div className={styles.card1}>
                    <div>
                        <h3>Wedding Ceremonies</h3>
                        <p>Wedding Ceremonies and Receptions</p>
                    </div>
                    <Link to='/request-quote'>
                        <button>Request a quote</button>
                    </Link>
                </div>
                <div className={styles.card2}>
                    <div>
                        <h3>Bridal Shower</h3>
                        <p>Cocktails, Catering, Gift-giving & More</p>
                    </div>
                    <Link to='/request-quote'>
                        <button>Request a quote</button>
                    </Link>
                </div>
                <div className={styles.card3}>
                    <div>
                        <h3>Coporate Events</h3>
                        <p>Businesses, Schools, Non-profits & More</p>
                    </div>
                    <Link to='/request-quote'>
                        <button>Request a quote</button>
                    </Link>
                </div>
                <div className={styles.card4}>
                    <div>
                        <h3>Social Events</h3>
                        <p>Concerts/Entertainments Memorable Moments</p>
                    </div>
                    <Link to='/request-quote'>
                        <button>Request a quote</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default RequestQuote;