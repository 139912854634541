import React from 'react'
import { Helmet } from 'react-helmet';
// import WhiteSocial from '../components/WhiteSocial';
import falomo2 from '../assets/images/falomo2.png'
import falomo3 from '../assets/images/falomo3.png'
import falomo4 from '../assets/images/falomo4.png'
import sponsor1 from '../assets/images/sponsor1.png'
import sponsor2 from '../assets/images/sponsor2.png'
import styles from '../scss/overall.module.scss';


const Falomo = () => {
  return (
    <div className={styles.falomoPage}>
      <Helmet>
        <title>Latest News | Falomo</title>
      </Helmet>
      <header>
        <h1>
          The Maiden Edition of Folumo
        </h1>
        {/* <WhiteSocial /> */}
      </header>
      <section>
        <h2>
          The Maiden Edition of Folumo
        </h2>
        <p>
          In the vibrant state of Ogun, Nigeria, a truly remarkable event took place on the second day of Ileya 2023, captivating audiences and leaving an indelible mark on the local cultural landscape. The maiden edition of Folumo, a grand celebration of art, music, and culture, birthed by a collaboration between Dele Odule Presentations and WosAm Arena, unfolded at the prestigious Ambali Multipurpose Centre in WosAm Arena, showcasing a mesmerizing fusion of talent and creativity
        </p>
      </section>
      <img src={falomo2} alt="" />
      <section>
        <h2>
          The Performance
        </h2>
        <p>
          Folumo celebrated the rich tapestry of Yoruba art and culture, paying homage to the culture and traditions of the Yoruba peoples of Western Nigeria. The event featured a live musicalperformance by the renowned Udo Mariam; a mesmerizing magic show put on by the Peller siblings, Zito and Nike; standup comedy by Woli Agba; and the highlight of the day -a grand stage drama featuring Nollywood giants - Dele Odule, Lateef Adedimeji, Peju Ogunmola, and Ibrahim Chatta. The lineup of talented artists left the audience enthralled, as they danced and sang along to the infectious rhythms, creating an electrifying atmosphere within the arena.
        </p>
      </section>
      <img src={falomo3} alt="" />
      <section>
        <h2>
          Attendance
        </h2>
        <p>
          The event was well attended and was graced by the presence of highly placed dignitaries including His Royal Highness Oba Abdulrazaq Adesina Adenugba, the Ebumawe of Ago Iwoye; Major General Awosanya (Baba Oba); Hon. Adesegun Adekoya; Dr. (Oluomo) Olufemi Bakre, MD, Parallex Bank; Omo’oba Mufutau Adekoya, chief host and Chairman, WosAm Arena Ltd; and a host of other dignitaries.
        </p>
      </section>
      <img src={falomo4} alt="" />
      <section>
        <h2>
          Sponsors
        </h2>
        <p>
          Folumo was sponsored by WosAm Arena Ltd and co sponsored by Standard Integrated Supports LL.C and Parallex Bank.
        </p>
        <div>
          <img src={sponsor1} alt="" />
          <img src={sponsor2} alt="" />
        </div>
        <p>
          Thanking guests for gracing the occasion, Mr. Dele Odule remarked that the event has come to stay and will only improve over the years. He called for sponsors to get behind Folumo to further place Ago-Iwoye and Ijebuland as passionate preservers of the rich Yoruba culture.Sponsors are invited for Folumo 2024. For enquiries: info@wosamarena.com 09166420010
        </p>
      </section>
    </div>
  )
}

export default Falomo