import React from "react";
import styles from './scss/overall.module.scss';
import { Routes, Route } from 'react-router-dom';

import { Footer, Navbar } from "./components";
import {
  Home, Overview, OurTeam, WeddingEvent, SocialEvent,
  CorporateEvent, WosamHotels, WosamEventHall, WosamApartments,
  WosamConferenceHall, Wosamonthemove, WosamConcertSpace, WosamCinema,
  WosamNightClub, WosamLounge, WosamCafe, RequestQuote, TwoBookings, ThreeBookings,
  Gallery, LatestNews, Newsletter, Career, ContactUs, DeluxeHotelBookings, StandardHotelBookings, ConcertSpaceBookings, EventHallBookings, ConferenceHallBookings, Falomo, EventBookingForm, Terms
} from "./pages";

import ScrollToTop from "./hoc/ScrollToTop";
// import { ToastContainer } from 'react-toastify';
// import EventBookingForm from './pages/EventBookingForm';

function App () {

  return (
    <div className={styles.container}>
      <Navbar />
      <ScrollToTop>
        {/* <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
        /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about/overview" element={<Overview />} />
          <Route path="/about/our-team" element={<OurTeam />} />
          <Route path="/events/wedding-events" element={<WeddingEvent />} />
          <Route path="/events/social-events" element={<SocialEvent />} />
          <Route path="/events/corporate-events" element={<CorporateEvent />} />
          <Route path="/facilities/wosam-hotels" element={<WosamHotels />} />
          <Route path="/facilities/wosam-event-center" element={<WosamEventHall />} />
          <Route path="/facilities/wosam-apartments" element={<WosamApartments />} />
          {/* <Route path="/facilities/wosam-conference-hall" element={<WosamConferenceHall />} /> */}
          <Route path="/facilities/wosamonthemove" element={<Wosamonthemove />} />
          {/* <Route path="/facilities/wosam-concert-space" element={<WosamConcertSpace />} /> */}
          <Route path="/facilities/wosam-cinema" element={<WosamCinema />} />
          <Route path="/facilities/wosam-night-club" element={<WosamNightClub />} />
          <Route path="/facilities/wosam-lounge" element={<WosamLounge />} />
          <Route path="/facilities/wosam-cafe" element={<WosamCafe />} />
          <Route path="/media/our-gallery" element={<Gallery />} />
          <Route path="/media/latest-news" element={<LatestNews />} />
          <Route path="/media/latest-news/1" element={<Falomo />} />
          <Route path="/media/newsletter" element={<Newsletter />} />
          <Route path="/request-quote" element={<RequestQuote />} />
          <Route path="/three-bookings" element={<ThreeBookings />} />
          <Route path="/two-bookings" element={<TwoBookings />} />
          <Route path="/standard-hotel-bookings" element={<StandardHotelBookings />} />
          <Route path="/deluxe-hotel-bookings" element={<DeluxeHotelBookings />} />
          <Route path="/concert-space-bookings" element={<ConcertSpaceBookings />} />
          {/* <Route path="/event-hall-bookings" element={<EventHallBookings />} />
          <Route path="/conference-hall-bookings" element={<ConferenceHallBookings />} /> */}
          <Route path="/career" element={<Career />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/event-booking-form" element={<EventBookingForm />} />
          <Route path="/terms-and-conditions" element={<Terms />} />
        </Routes>
      </ScrollToTop>
      <Footer />
    </div>
  );
}

export default App;
