import React from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import styles from '../scss/overall.module.scss';
import { ReactComponent as Phone } from '../assets/icons/icons8_phone.svg';
import { ReactComponent as AtSign } from '../assets/icons/icons8_At_sign.svg';

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_USERID;

const RequestQuote = () => {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (e) => {
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, '#form', USER_ID)
      .then(
        (result) => {
          // console.log(result)
          toast.success("Message Sent Successfully");
        },
        (err) => {
          toast.error("Something went wrong. Try again");
          // console.log(err.text);
        }
      );
    reset();
  };

  const handleError = (errors) => console.log(errors);

  return (
    <div className={styles.requestQuote3}>
      <Helmet>
        <title>Reques Quote | WosAm.com</title>
      </Helmet>
      <div className={styles.content}>
        <h3>Request a quote</h3>
        <p>Would like to  request a quote or just have a few questions about our event space, we’re happy to help!  Simply fill out the form below and one of our professional Event Specialists will be in touch with you soon.</p>
      </div>
      <div className={styles.formContainer}>
        <h3>Send us a request</h3>
        <form id='form' onSubmit={handleSubmit(onSubmit, handleError)}>
          <input type="text" placeholder='Name' name='name' {...register("name")} />
          <input type="text" placeholder='Phone number' name='number' {...register("number")} />
          <input type="text" placeholder='Email' name='email' {...register("email")} />
          <input type="text" placeholder='Concert/Entertainment' {...register("Concert/Entertainment")} />
          <input type="text" placeholder='Organization Name (If applicable)' {...register("Organization Name")} />
          <input type="text" placeholder='Estimated Number of Guests' {...register("Estimated Number oof Guests")} />
          <input type="text" placeholder='Event Date' {...register("Event Date")} />
          <textarea name="" id="" cols="30" rows="10" placeholder='Message' {...register("message")} />
          <button type="submit" >Send</button>
        </form>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
        />;
        <div className={styles.contacts}>
          <span><AtSign className={styles.icon} /> info@wosamarena.com</span>
          <span><Phone className={styles.icon} /> 09164418000, 09153253750</span>
        </div>
      </div>
    </div>
  );
};

export default RequestQuote;