import React from 'react';
import { Helmet } from 'react-helmet';
import styles from '../../scss/overall.module.scss';
import Review from '../../components/Review';
import WhiteSocial from '../../components/WhiteSocial';
import { ReactComponent as Capacity } from '../../assets/icons/icons8_museum_1.svg';
import { ReactComponent as Linens } from '../../assets/icons/icons8_blankie.svg';
import { ReactComponent as Bartenders } from '../../assets/icons/icons8_man_in_a_tuxedo_1.svg';
import { ReactComponent as Chair } from '../../assets/icons/icons8_chair.svg';
import { ReactComponent as Management } from '../../assets/icons/icons8_management.svg';
import { ReactComponent as Security } from '../../assets/icons/icons8_security_guard_1.svg';
import card from '../../assets/images/Wosam Icream shop and cafe.png';

const WosamCafe = () => {
  return (
    <div className={styles.wosamCafe}>
      <Helmet>
        <title>Wosam Cafe | WosAm.com</title>
      </Helmet>
      <header>
        <h3>- WOSAM CAFE -</h3>
        <h1>Wosam Café is one of a kind, don't believe Us?</h1>
        <p>wosam café in case you were wondering what a café in ago iwoye is like; we have a nice selection of tea and would be ideal for afternoon tea or breakfast in Ogun state, with very classic and appealing aesthetics and a warm environment.</p>

        <button>View Gallery</button>
        <WhiteSocial />
      </header>
      <p>Scroll down</p>
      <h2>Our Cafe</h2>
      <div className={styles.firstCard}>
        <div className={styles.firstCardContent}>
          <h2>The Cafe</h2>
          {/* <h3>₦800,000/day</h3> */}
          <p>Thinking of a nice café near you? WosAm café in case you were wondering what a café in ago iwoye is like; we have a nice selection of tea and would be ideal for afternoon tea or breakfast, your choice.</p>
          <button>View Gallery</button>
          <div className={styles.newSpaces}>
            <div className={styles.newSpace}>
              <Capacity className={styles.spaceIcon} />
              <div>
                <h3>Capacity</h3>
                <p>200</p>
              </div>
            </div>
            <div className={styles.newSpace}>
              <Linens className={styles.spaceIcon} />
              <div>
                <h3>Linens</h3>
                <p>Available</p>
              </div>
            </div>
            <div className={styles.newSpace}>
              <Bartenders className={styles.spaceIcon} />
              <div>
                <h3>Bartenders</h3>
                <p>All Inclusive</p>
              </div>
            </div>
            <div className={styles.newSpace}>
              <Chair className={styles.spaceIcon} />
              <div>
                <h3>Table & Chair</h3>
                <p>Setup & Clean</p>
              </div>
            </div>
            <div className={styles.newSpace}>
              <Management className={styles.spaceIcon} />
              <div>
                <h3>Management</h3>
                <p>On-Site</p>
              </div>
            </div>
            <div className={styles.newSpace}>
              <Security className={styles.spaceIcon} />
              <div>
                <h3>Security Guards</h3>
                <p>Indoor & Outdoor</p>
              </div>
            </div>

          </div>
        </div>
        <img src={card} alt="welcome Img" />
      </div>
      <Review />
    </div>
  );
};

export default WosamCafe;