import quote from '../assets/images/avatar.png';
import quote2 from '../assets/images/avatar2.png';
import quote1 from '../assets/images/avatar.png';
import slide1 from '../assets/images/slide01.png';
import slide2 from '../assets/images/slide02.png';
import slide3 from '../assets/images/slide03.png';
import slide4 from '../assets/images/slide04.png';
import slide5 from '../assets/images/slide05.png';
// import hotelImage from '../assets/images/Best Hotel In Ogun state Ago Iwoye.png';
// import apartmentImage from '../assets/images/Best Apartment shortlet In Ogun state Ago Iwoye.png';
import truckImage from '../assets/images/Car Rental In Wosam on the move.png';
// import cinemaImage from '../assets/images/Wosam Cinemas in Ogun State.png';
import eventImage from '../assets/images/Event Hall Event Center In Ago Iwoye Ogun State.png';
import conferenceImage from '../assets/images/Conference space Hall In Ago Iwoye.png';
import concertImage from '../assets/images/Concert Space In Ogun state.png';
import clubImage from '../assets/images/Night Club In Ogun state Ago Iwoye.png';
import loungeImage from '../assets/images/Coolest spot in Ogun state Ago iwoye wosam lounge.png';
import cafeImage from '../assets/images/Ice cream cafe cofee spots in Ogun state Ago Iwoye.png';
import { barIcon, buffetIcon, complimentaryIcon, fitnessIcon, moreIcon, newCinemaImage, newHotelImage, newImage2, poolIcon, roomServiceIcon, spaIcon, tvIcon, wifiIcon } from '../assets';

export const aboutLinks = [
  {
    id: 1,
    title: 'Overview',
    path: 'about/overview',
  },
  {
    id: 2,
    title: 'Our Team',
    path: 'about/our-team',
  }
];

export const eventsLinks = [
  {
    id: 1,
    title: 'Wedding Events',
    path: 'events/wedding-events',
  },
  {
    id: 2,
    title: 'Social Events',
    path: 'events/social-events',
  },
  {
    id: 3,
    title: 'Coporate Events',
    path: 'events/corporate-events',
  }
];

export const facilitiesLinks = [
  {
    id: 1,
    title: 'Wosam Hotels',
    path: 'facilities/wosam-hotels',
  },
  {
    id: 2,
    title: 'Wosam Event Center',
    path: 'facilities/wosam-event-center',
  },
  {
    id: 3,
    title: 'Wosam Apartments',
    path: 'facilities/wosam-apartments',
  },
  // {
  //   id: 4,
  //   title: 'Wosam Conference Hall',
  //   path: 'facilities/wosam-conference-hall',
  // },
  {
    id: 5,
    title: 'Wosamonthemove',
    path: 'facilities/wosamonthemove',
  },
  // {
  //   id: 6,
  //   title: 'Wosam Concert Space',
  //   path: 'facilities/wosam-concert-space',
  // },
  {
    id: 7,
    title: 'Wosam Cinema',
    path: 'facilities/wosam-cinema',
  },
  {
    id: 8,
    title: 'Wosam Night Club',
    path: 'facilities/wosam-night-club',
  },
  {
    id: 9,
    title: 'Wosam Lounge',
    path: 'facilities/wosam-lounge',
  },
  {
    id: 10,
    title: 'Wosam Cafe',
    path: 'facilities/wosam-cafe',
  }
];

export const mediaLinks = [
  {
    id: 1,
    title: 'Our Gallery',
    path: 'media/our-gallery',
  },
  {
    id: 2,
    title: 'Latest News',
    path: 'media/latest-news',
  },
  {
    id: 3,
    title: 'Newsletter',
    path: 'media/newsletter',
  }
];

export const review = [
  {
    text: 'Superb one-stop shop! Events center, hotel, club, outdoor bar. Courteous staff and management, all areas are well maintained, reasonable rates.',
    name: 'Tobi Olukoya',
    img: quote,
    bgImg: quote1
  },
  {
    text: 'Superb one-stop shop! Events center, hotel, club, outdoor bar. Courteous staff and management, all areas are well maintained, reasonable rates.',
    name: 'Bisi Michael',
    img: quote1,
    bgImg: quote2
  },
  {
    text: 'Superb one-stop shop! Events center, hotel, club, outdoor bar. Courteous staff and management, all areas are well maintained, reasonable rates.',
    name: 'Dare Olumide',
    img: quote2,
    bgImg: quote1
  },
];

export const slideImg = [
  {
    img: slide1
  },
  {
    img: slide2
  },
  {
    img: slide3
  },
  {
    img: slide4
  },
  {
    img: slide5
  },
];


export const homeSlide = [
  {
    h2: 'Wosam Hotels',
    p1: 'Wosam Hotel has a great impact and is one of the best Hotel in Ago-Iwoye Ogun state, Nigeria. We have cozy hotels for relaxation with a comfortable ambience,',
    p2: 'we offer our guests our unique blend of global 5-star finesse and world-class comfort here in Ogun State and we are ready to serve you. Coupled with our affordability and convenient location, you just can’t go wrong.',
    img: newHotelImage,
    link: 'facilities/wosam-hotels'
  },
  {
    h2: 'Wosam Apartments',
    p1: 'Wosam Apartment has a great impact and is one of the best Hotel in Ago-Iwoye Ogun state, Nigeria. We have cozy hotels for relaxation with a comfortable ambience,',
    p2: 'we offer our guests our unique blend of global 5-star finesse and world-class comfort here in Ogun State and we are ready to serve you. Coupled with our affordability and convenient location, you just can’t go wrong.',
    img: newImage2,
    link: 'facilities/wosam-apartments'
  },
  {
    h2: 'Wosam on the move',
    p1: 'Yes! Affordable and convenient car rentals for your needs, save on last minutes fast and secure booking of comfortable rides and 24/7 customer services',
    p2: 'Take this advantage hit the road and enjoy class and comfort with wosamonthemove car rental service in Ago-Iwoye, Ogun State. Coupled with our affordability and convenient location, you just can’t go wrong',
    img: truckImage,
    link: 'facilities/wosamonthemove'
  },
  {
    h2: 'Wosam Cinema',
    p1: 'Every event at wosam is a fully specialized experience uniquely designed to your specific vision and requirement. Wosam cinema is a unique film exhibition place with style that incorporates diverse features',
    p2: 'Including cutting-edge films luxurious dines in cinema with the best views to see a movie and professional operations management',
    img: newCinemaImage,
    link: 'facilities/wosam-cinema'
  },
  {
    h2: 'Wosam Event Hall',
    p1: 'Wosam arena has a perfectly planned centre situated in Ago-iwoye, Ogun State. That is appropriate for both huge and little events',
    p2: 'whether it is a private get-together for limited guests or a grand gala for large amount of people, our event centre is prepared to assist you with making a remarkable encounter for yourself as well as your visitors',
    img: eventImage,
    link: 'facilities/wosam-event-hall'
  },
  {
    h2: 'Wosam Conference Hall',
    p1: 'With an arena like this, conferences are definitely not left out, our conference hall is our largest space area, with upgraded lightening, a big screen with projectors for presentation and all of the amenities required in a standard conference hall so you definitely have nothing to lose.',
    p2: 'All here in one of the best Conference Hall in Ago-iwoye, Ogun state',
    img: conferenceImage,
    link: 'facilities/wosam-conference-hall'
  },
  {
    h2: 'Wosam Concert Space',
    p1: 'Wosam Hotel has a great impact and is one of the best hotel in Ago-iwoye Ogun state, Nigeria. We have cozy hotels for relaxation with a comfortable ambience.',
    p2: 'we offer our guests with unique blend of global 5-star finesse and world-class comfort here in Ogun state and we are ready to serve you. Coupled with our affordability and convenient location, you just can’t go wrong',
    img: concertImage,
    link: 'facilities/wosam-concert-space'
  },
  {
    h2: 'Wosam Night Club',
    p1: 'At wosam arena, the party never stops; an exquisite embodiment of a luxury fulfilled lifestyle our plan is to develop a superior nightlife experience.',
    p2: 'We have come to keep on setting the benchmark for the nightlife here in Ago-iwoye, Ogun state. We represent elegant intention for a satisfying evening out in the town with that said, you are most welcome to partake in the city’s superior nightlife. So come on let’s partaasy',
    img: clubImage,
    link: 'facilities/wosam-night-club'
  },
  {
    h2: 'Wosam Lounge',
    p1: 'With the coolest spots in Ogun state, we have the wosam lounge here in Ago-iwoye, consisting of course, the best dishes both African and international.',
    p2: 'Also out top-notch mocktails and cocktails with great music great ambient live music from the best bands. Come through to relax and have an amazing time',
    img: loungeImage,
    link: 'facilities/wosam-lounge'
  },
  {
    h2: 'Wosam Cafe',
    p1: 'Thinking of a nice cafe near you? We have you covered at wosam cafe in case you were wondering what a cafe in ago-iwoye looks like.',
    p2: 'We have a nice selection of tea and would be ideal for afternoon tea or breakfast and most definitely brunch in Ogun state, with very classic and appealing aesthetics and a warm environment perfect for tranquil relaxation. It is definitely safe to say we have a replica of cafe here in Ogun state.',
    img: cafeImage,
    link: 'facilities/wosam-cafe'
  },
];

export const features = [
  {
    id: 1,
    featureicon: spaIcon,
    featureName: 'Spa',
  },
  {
    id: 2,
    featureicon: complimentaryIcon,
    featureName: 'Complimentary Breakfast',
  },
  {
    id: 3,
    featureicon: buffetIcon,
    featureName: 'Buffet Services',
  },
  {
    id: 4,
    featureicon: poolIcon,
    featureName: 'Swimming Pools',
  },
  {
    id: 5,
    featureicon: roomServiceIcon,
    featureName: 'Room Service',
  },
  {
    id: 6,
    featureicon: wifiIcon,
    featureName: 'Wifi',
  },
  {
    id: 7,
    featureicon: barIcon,
    featureName: 'Restaurant & Bar',
  },
  {
    id: 8,
    featureicon: fitnessIcon,
    featureName: 'Fitness Centre',
  },
  {
    id: 9,
    featureicon: tvIcon,
    featureName: 'Smart TV',
  },
  {
    id: 10,
    featureicon: moreIcon,
    featureName: '& Much More',
  },
  
];