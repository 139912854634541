import React from 'react';
import CountUp from 'react-countup';

import styles from '../scss/overall.module.scss';
import { ReactComponent as EventHall } from '../assets/icons/homeMuseum.svg';
import { ReactComponent as MPH } from '../assets/icons/icons8_city_hall.svg';
import { ReactComponent as VIP } from '../assets/icons/icons8_vip.svg';
import { ReactComponent as Outdoor } from '../assets/icons/icons8_lake.svg';


const Statistics = () => {
    return (
        <div className={styles.statistics}>
            <div className={styles.statistic}>
                <EventHall className={styles.statisticIcon} />
                <div>
                    <h3><CountUp start={1} end={2500} separator="," redraw={true} enableScrollSpy={true} />+</h3>
                    <p>Event Hall</p>
                </div>
            </div>
            <div className={styles.statistic}>
                <MPH className={styles.statisticIcon} />
                <div>
                    <h3><CountUp start={1} end={2500} separator="," redraw={true} enableScrollSpy={true} />+</h3>
                    <p>Multipurpose Hall</p>
                </div>
            </div>
            <div className={styles.statistic}>
                <VIP className={styles.statisticIcon} />
                <div>
                    <h3><CountUp start={1} end={200} separator="," redraw={true} enableScrollSpy={true} /></h3>
                    <p>VIP Lounge</p>
                </div>
            </div>
            <div className={styles.statistic}>
                <Outdoor className={styles.statisticIcon} />
                <div>
                    <h3><CountUp start={1} end={200} separator="," redraw={true} enableScrollSpy={true} />+</h3>
                    <p>Outdoor Function Area</p>
                </div>
            </div>
        </div>
    );
};

export default Statistics;