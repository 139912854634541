import React from 'react';
import {
  Contact, Facilities,
  FirstCard, RequestQuote, Review,
  SecondCard, Social, Statistics, NewsLetter
} from '../components';

const Home = () => {
  return (
    <div style={{ position: 'relative' }}>
      <FirstCard />
      <Social />
      <Statistics />
      <Facilities />
      <SecondCard />
      <Review />
      <RequestQuote />
      <NewsLetter />
      <Contact />
    </div>
  );
};

export default Home;
