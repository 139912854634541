import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import styles from '../../scss/overall.module.scss';
import '../../tailwindCss.css'
import Review from '../../components/Review';
import WhiteSocial from '../../components/WhiteSocial';
import { ReactComponent as Capacity } from '../../assets/icons/icons8_museum_1.svg';
import { ReactComponent as Linens } from '../../assets/icons/icons8_blankie.svg';
import { ReactComponent as Bartenders } from '../../assets/icons/icons8_man_in_a_tuxedo_1.svg';
import { ReactComponent as Chair } from '../../assets/icons/icons8_chair.svg';
import { ReactComponent as Management } from '../../assets/icons/icons8_management.svg';
import { ReactComponent as Security } from '../../assets/icons/icons8_security_guard_1.svg';
import card from '../../assets/images/eventHallCard.jpg';

const WosamEventHall = () => {
  const navigate = useNavigate();
  const down = useRef(null);

  const scrollDown = () => {
    window.scrollTo({
      top: down.current.offsetTop,
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.wosamEventHall}>
      <Helmet>
        <title>WosAm Event Center | WosAm.com</title>
      </Helmet>
      <header>
        <h3 className='text-2xl'>- WOSAM EVENT CENTER -</h3>
        <h1>Let's make your event memorable </h1>
        <p>WosAm Arena is the perfect place to host your events, from wedding ceremony, engagement, reception, burial, birthday party, class reunion, christmas party, bridal Shower, rehearsal dinners, cocktail receptions, fundraisers & galas, holiday parties, sports banquet, corporate meeting and much more! We are at your service.</p>
        {/* <button onClick={() => navigate('/event-booking-form')}>Book Now</button>
        <WhiteSocial /> */}
      </header>
      <p></p>
      <h2></h2>
      <div className={styles.firstCard}>
        <div className={styles.firstCardContent} ref={down}>
          <h2>WosAm Event Center</h2>
          {/* <h3>₦1,700,000/day</h3> */}
          <p>The WosAm Event Center embodies class, comfort and capacity with multiple event halls and a concert space. All halls are equipped with beautiful lightings, 24/7 cooling and uninterrupted power. Whatever your occasion is, we’ve got the perfect space for you.</p>
          {/* <button onClick={() => navigate('/event-hall-bookings')}>Book Now</button> */}
          <button onClick={() => navigate('/event-booking-form')}>Book Now</button>
          <div className={styles.newSpaces}>
            <div className={styles.newSpace}>
              <Capacity className={styles.spaceIcon} />
              <div>
                <h3>Capacity</h3>
                <p>2500</p>
              </div>
            </div>
            <div className={styles.newSpace}>
              <Chair className={styles.spaceIcon} />
              <div>
                <h3>Table & Chair</h3>
                <p>Setup & Clean</p>
              </div>
            </div>
            <div className={styles.newSpace}>
              <Management className={styles.spaceIcon} />
              <div>
                <h3>Management</h3>
                <p>On-Site</p>
              </div>
            </div>
            <div className={styles.newSpace}>
              <Security className={styles.spaceIcon} />
              <div>
                <h3>Security Guards</h3>
                <p>Indoor & Outdoor</p>
              </div>
            </div>

          </div>
        </div>
        <img src={card} alt="welcome Img" />
      </div>

      <Review />

    </div>
  );
};

export default WosamEventHall;