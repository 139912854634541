import React from 'react';
import { Helmet } from 'react-helmet';
import styles from '../../scss/overall.module.scss';
import { Link } from 'react-router-dom';

const SocialEvent = () => {
  return (
    <div className={styles.socialEvent}>
      <Helmet>
        <title>Social Event | WosAm.com</title>
      </Helmet>
      <div className={styles.burna}>
        <h2>Social Events</h2>
        <h4>- FOR ANY CELEBRATION-</h4>
        <p>In life we all have events that need to be celebrated and remembered, and Promoting Memorable Moments for you and your guests is what happens when you host your social event, special occasion celebration, or private party at WosAm Arena.</p>
        <button>Book our Hall</button>
      </div>
      <div className={styles.subHeader}>
        <span></span>
        <h2>Memorable Events</h2>
        <p>Promoting Memorable is what we want for you with out the necessities of breaking the bank! Just request for a quote for any of our services you need and your budget will genuinely be observed</p>
      </div>
      <div className={styles.cards}>
        <div className={styles.card1}>
          <div>
            <h3>Birthday Parties</h3>
            <p>Wedding Ceremonies and Receptions</p>
          </div>
          <Link to='/request-quote'><button>Request a quote</button></Link>
        </div>
        <div className={styles.card2}>
          <div>
            <h3>Sweet 16</h3>
            <p>Cocktails, Catering, Gift-giving & More</p>
          </div>
          <Link to='/request-quote'><button>Request a quote</button></Link>
        </div>
        <div className={styles.card3}>
          <div>
            <h3>Anniversaries</h3>
            <p>Businesses, Schools, Non-profits, & More</p>
          </div>
          <Link to='/request-quote'><button>Request a quote</button></Link>
        </div>
        <div className={styles.card4}>
          <div>
            <h3>Retirement</h3>
            <p>Concerts/Entertainment Memorable Moments</p>
          </div>
          <Link to='/request-quote'><button>Request a quote</button></Link>
        </div>
        <div className={styles.card5}>
          <div>
            <h3>Concert or Entertainment</h3>
            <p>Wedding Ceremonies and Receptions</p>
          </div>
          <Link to='/request-quote'><button>Request a quote</button></Link>
        </div>
        <div className={styles.card6}>
          <div>
            <h3>Graduation</h3>
            <p>Cocktails, Catering, Gift-giving & More</p>
          </div>
          <Link to='/request-quote'><button>Request a quote</button></Link>
        </div>
        <div className={styles.card7}>
          <div>
            <h3>Quinceaneras</h3>
            <p>Businesses, Schools, Non-profits, & More</p>
          </div>
          <Link to='/request-quote'><button>Request a quote</button></Link>
        </div>
        <div className={styles.card8}>
          <div>
            <h3>Burials or Remembrance</h3>
            <p>Concerts/Entertainment Memorable Moments</p>
          </div>
          <Link to='/request-quote'><button>Request a quote</button></Link>
        </div>
      </div>
    </div>
  );
};

export default SocialEvent;
