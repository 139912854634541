import React from 'react';
import styles from '../scss/overall.module.scss';

const Facilities = () => {
    return (
        <div className={styles.facilities}>
            <header>
                <h2>Our Facilities & Services</h2>
            </header>
            <section>
                <span className={styles.textSpan}>
                    We are an arena with revel in full service management, we provide 24/7 power alongside a standby generator, we also make certain that our guests aren't strained with network troubles so we offer WIFI access to all. Furthermore a call line inside the reception in case you need to contact your family and friends,
                </span>

                <span className={styles.textSpan}>
                    and also intercom telephones assigned to an awesome range of places round in order to attain our staff hassle. Our major aim is to fulfill our guests in every feasible way. We are very security cautious and so we offer very efficient protection/security services to ensure our safety.
                </span>
            </section>
        </div>
    );
};

export default Facilities;