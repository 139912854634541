import React from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useForm } from 'react-hook-form';
import styles from '../scss/overall.module.scss';
import { ReactComponent as Phone } from '../assets/icons/icons8_phone.svg';
import { ReactComponent as AtSign } from '../assets/icons/icons8_At_sign.svg';

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID_CONTACT;
const USER_ID = process.env.REACT_APP_USERID;

const Contact = () => {
    const { register, handleSubmit, reset } = useForm();

    const onSubmit = (e) => {
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, '#form', USER_ID)
            .then(
                (result) => {
                    // console.log(result)
                    toast.success("Message Sent Successfully");
                },
                (err) => {
                    toast.error("Something went wrong. Try again");
                    // console.log(err.text);
                }
            );
        reset();
    };

    const handleError = (errors) => console.log(errors);

    return (
        <div className={styles.contact}>
            <div className={styles.content}>
                <h3>Contact Us</h3>
                <p>Whether you’re ready to plan an occasion down to the very last detail, would like to  request a quote or just have a few questions about our event space, we’re happy to help!  Simply fill out the form below and one of our professional Event Specialists will be in touch with you soon.</p>
            </div>
            <div className={styles.formContainer}>
                <h3>Send us an E-Mail</h3>
                <form id='form' onSubmit={handleSubmit(onSubmit, handleError)}>
                    <input type="text" placeholder='Enter your name' name='name' {...register("name")} />
                    <input type="email" placeholder='Enter your email' name='email' {...register("email")} />
                    <input type="text" name= 'subject' placeholder='Subject' {...register("subject")} />
                    <textarea name="message" id="" cols="30" rows="10" placeholder='Message' {...register("message")} />
                    <button type="submit" >Send</button>
                </form>
                <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    pauseOnHover={false}
                />
                <div className={styles.contacts}>
                    <span><AtSign className={styles.icon} /> info@wosamarena.com</span>
                    <span><Phone className={styles.icon} /> 09164418000, 09164419000, 09153253750</span>
                </div>
            </div>

        </div>
    );
};

export default Contact;