import React, { useState, useRef, useEffect } from 'react';
import { PaystackButton } from 'react-paystack';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import moment from 'moment/moment';

import icons8_bed from '../assets/images/icons8_bed.png';
import icons8_living_room from '../assets/images/icons8_living_room.png';
import icons8_tv from '../assets/images/icons8_tv.png';
import icons8_telephone from '../assets/images/icons8_telephone.png';
import icons8_air_conditioner from '../assets/images/icons8_air_conditioner.png';
import icons8_wi_fi_logo from '../assets/images/icons8_wi_fi_logo.png';
import icons8_gps_signal from '../assets/images/icons8_gps_signal.png';
import icons8_towel_1 from '../assets/images/icons8_towel_1.png';
import icons8_toilet_paper from '../assets/images/icons8_toilet_paper.png';
import icons8_housekeeping from '../assets/images/icons8_housekeeping.png';

import styles from '../scss/overall.module.scss';

const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;

const DeluxeHotelBookings = () => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [checkIn, setCheckIn] = useState('');
    const [checkOut, setCheckOut] = useState('');
    const [displayAmount, setDisplayAmount] = useState('');

    const formRef = useRef();

    const handleCheckin = e => setCheckIn(e.target.value);
    const handleCheckout = e => setCheckOut(e.target.value);

    const startDate = moment(checkIn);
    const endDate = moment(checkOut);

    const days = endDate.diff(startDate, 'days');

    useEffect(() => {
        const interval = setInterval(() => {
            setDisplayAmount(30000 * days);
        }, 2000);
        return () => clearInterval(interval);
    }, [days]);

    const amount = 30000 * 100 * days;

    const componentProps = {
        email,
        amount,
        metadata: {
            name,
            phone,
        },
        publicKey,
        text: "Proceed to Payment",
        onSuccess: () => {
            toast.success("Payment Successful");
            formRef.current.reset();
        },
        onClose: () => {
            toast.error("Something went wrong. Try again");
            formRef.current.reset();
        },
    };

    return (
        <div className={styles.bookings}>
            <Helmet>
                <title>Bookings | WosAm.com</title>
            </Helmet>
            <div className={styles.upperContents}>
                <div className={styles.content}>
                    <h1>DELUXE SUITE</h1>
                    <span>NGN 30,000</span>
                    <p>per day night</p>
                </div>

                <div className={styles.formContainer}>
                    <h3>Enter your details</h3>
                    <form onSubmit={e => e.preventDefault()} ref={formRef}>
                        <label htmlFor="Check-In Date">Check-In Date</label>
                        <input type="date" onChange={handleCheckin} value={checkIn} />
                        <label htmlFor="Check-Out Date">Check-Out Date</label>
                        <input type="date" name="Check-Out Date" id="" onChange={handleCheckout} value={checkOut} />
                        <input type="text" placeholder='Enter your Full Name' name='name' onChange={e => setName(e.target.value)} />
                        <input type="text" placeholder='Enter your Email Address' name='email' onChange={e => setEmail(e.target.value)} />
                        <input type="text" placeholder='Enter your Phone Number' name='number' onChange={e => setPhone(e.target.value)} />
                        <span>Cost: NGN {displayAmount}</span>
                        <PaystackButton className="paystack-button" {...componentProps} />
                    </form>
                </div>
            </div>

            <div className={styles.lowerContents}>
                <span>- WOSAM APARTMENT -</span>
                <h1>Apartment Details</h1>
                <p>Our spacious “ Classic Apartment” is a single standard room that comes with air conditioning, complimentary in-room bottled water, toiletries, tea and coffee, Wi-fi. Also includes daily housekeeping services, extra towels, smart TV (plus 15 Dstv channels) a shower, access to a fitness center, pool and The Landmark Leisure Beach. Room accessories include 1 bed, sofa chair, a work desk, 1 intercom telephone, a safe box, an enclosed fridge, wardrobe. Maximum occupancy is 2 persons. Complimentary breakfast for 1 per room Views include a stunning ocean view from a high floor.</p>
                <h1>Apartment Facilities</h1>
                <div className={styles.images}>
                    <div className={styles.box}>
                        <img src={icons8_bed} alt="img" />
                        <span>1 Bed</span>
                    </div>
                    <div className={styles.box}>
                        <img src={icons8_living_room} alt="img" />
                        <span>1 sofa chair</span>
                    </div>
                    <div className={styles.box}>
                        <img src={icons8_tv} alt="img" />
                        <span>TV</span>
                    </div>
                    <div className={styles.box}>
                        <img src={icons8_air_conditioner} alt="img" />
                        <span>AC</span>
                    </div>
                    <div className={styles.box}>
                        <img src={icons8_telephone} alt="img" />
                        <span>Telephone</span>
                    </div>
                    <div className={styles.box}>
                        <img src={icons8_wi_fi_logo} alt="img" />
                        <span>Free WIFI</span>
                    </div>
                    <div className={styles.box}>
                        <img src={icons8_gps_signal} alt="img" />
                        <span>DSTV</span>
                    </div>
                    <div className={styles.box}>
                        <img src={icons8_towel_1} alt="img" />
                        <span>1 Towel</span>
                    </div>
                    <div className={styles.box}>
                        <img src={icons8_toilet_paper} alt="img" />
                        <span>Toiletries</span>
                    </div>
                    <div className={styles.box}>
                        <img src={icons8_housekeeping} alt="img" />
                        <span>Housekeeping</span>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h2>Cost Per Night: <strong>NGN 30,000</strong></h2>
                </div>
            </div>
        </div>
    );
};

export default DeluxeHotelBookings;