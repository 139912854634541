import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import '../tailwindCss.css'

const Terms = () => {
  const history = useNavigate()
  return (
    <div className='relative sm:py-40 py-28 sm:px-[80px] px-4'>
      <div className="w-full flex justify-center">
        <h2 className="text-center sm:text-4xl text-xl font-bold text-primary sm:mb-10 mb-8 sm:w-[70%] w-[75%]">
          Terms and Conditions for Booking and Reservation of Event Halls at WosAm Arena
        </h2>
      </div>
      <div
        className="absolute flex items-center gap-3 sm:top-44 top-28 sm:left-[80px] left-4 cursor-pointer text-primary"
        onClick={() => history(-1)}
      >
        {/* <img src={goBack} alt="" className="" /> */}
        <span className='sm:text-xl text-xs font-bold'>Go Back</span>
      </div>
      <p className="sm:text-lg">
        This Agreement outlines the terms and conditions under which event halls at Wosam Arena Limited (“<span className="font-bold">WosAm Arena</span>") are booked and reserved. By making a booking and reservation, you ("Renter") agree to adhere to these terms and conditions.
        <br /><br />
        <span className="font-bold">Booking and Reservation:</span>
        <br />

        Payment may be by cash, card or transfer to <span className="font-bold">WosAm Arena</span>. Evidence of electronic transfer payments may be sent to reservations@wosamarena.com. Card payments are accepted.
        <br />
        The Renter must provide accurate and complete information regarding the event, including the date, time, estimated attendance, and any specific requirements.
        <br />
        <span className="font-bold">WosAm Arena</span> reserves the right to decline any booking request at its sole discretion.
        <br />
        A reservation will become valid and official when a contract is signed and a deposit of 40% or more of the rental fee is received. The rental fee will include: use of the hall, lobby area, and beverage area. Free parking is provided. Food preparation areas are subject to appropriate fees and submission of credentials. The rental fee must be paid in full thirty [30] days prior to the event. Cash, card and bank transfer are acceptable means of payment. Cheques should be made payable to <span className="font-bold">WosAm Arena</span> and can be mailed or hand delivered to <span className="font-bold">WosAm Arena</span> at 119 Awolowo Rd, Ago-Iwoye. Returned cheques are subject to a Fifty Thousand Naira (₦50,000.00) fee.
        <br /><br />
        <span className="font-bold">Payments:</span>
        <br />

        A non-refundable booking deposit equivalent to 40% of the total rental amount is required to secure the reservation. This deposit will be credited towards the total rental fee.
        <br />
        The rental fee must be paid in full thirty [30] days prior to the event. Failure to complete payment by this date may result in the cancellation of the reservation.
        <br />
        All payments shall be exclusive of applicable taxes.
        <br />
        A refundable caution fee of ₦200,000.00 shall be paid by the Renter in addition to the rental fee. This fee does not apply toward the rental fee.
        <br />
        The total rental sum for reservations made within 30 days of the event date must be paid in full. Deposits will not be accepted.
        <br />
        Date changes attract a 20% administrative fee and must be made not later than 30 days before the reserved date.
        <br />
        All forms of payment are accepted. Cheques should be made payable to <span className="font-bold">WosAm Arena Ltd</span> and can be mailed or hand delivered to <span className="font-bold">WosAm Arena</span> at 119 Obafemi Awolowo Rd, Ago-Iwoye. Returned cheques are subject to a Fifty Thousand Naira (₦50,000.00) fee.
        <br /><br />
        <span className="font-bold">Cancellation and Refunds:</span>
        <br />
        
        Cancellation of a reservation by the Renter must be made in writing. The booking deposit is non-refundable.
        <br />
        In the event of cancellation within five (5) business days from the date of signed contract, the deposit will be refunded less twenty percent [20%] administrative fee. If the event is cancelled after five [5] business days from the date of signed contract, the entire deposit, plus any payments made from total balance, will be forfeited.
        <br />
        All cancellations must be submitted in writing to the management of <span className="font-bold">WosAm Arena. Telephone cancellations WILL NOT be accepted.</span> Changes in event dates are treated as a cancellation. To secure a new event date, the original date must first be cancelled. A new deposit will be required for the new date.
        <br />
        Renter is responsible for notifying <span className="font-bold">WosAm Arena</span> management, in writing, in the event of change of address. If notification of the above is not received, the refund cheque (if applicable) will be mailed to the "last known address”.
        <br />
        Security deposits are refunded within one (1) week after the event provided there are no damages, thefts, extra cleaning charges or overtime charges.
        <br /><br />
 
        <span className="font-bold">Event Details:</span>
        <br />

        The Renter is responsible for providing accurate event details, including setup requirements, equipment needs, and any other specific requests.
        <br />

        Any changes to the event details must be communicated to WosAm Arena in writing and approved by both parties.
        <br />

        The Renter is responsible for providing adequate security within and around the event hall.
        <br />

        The maximum capacity for events are 900 in Wosilat Hall, 700 in Ambali Hall, and 70 in the Banquet Hall (including band members, catering personnel and <span className="font-bold">WosAm Arena</span> staff). <span className="font-bold">WosAm Arena</span> strictly upholds the capacity restrictions. If the maximum capacities are exceeded, management reserves the right to "shut down" the event.
        <br />

        The Hall is open for use for a period of 8 hours ending at 8pm. An allowance can be made for an extension by prior agreement with WosAm Arena. An additional fee will be charged per hour and is payable before the extension is granted. There are no extensions on the Banquet Hall.
        <br />

        On the day of your event, <span className="font-bold">WosAm Arena</span> will be open for the contracted hours as stated in the rental agreement. All activities related to the Renter's events (e.g., decorating, deliveries, caterer, clean-up, etc.) must be done within the contracted rental time. During the contracted rental hours, a <span className="font-bold">WosAm Arena</span> representative will be present and shall have sole authority at all times regarding use of the facility.
        <br />

        <span className="font-bold">WosAm Arena</span> office hours are from 8am to 6pm Monday - Friday and 8am to 3pm on Saturdays.
        <br /><br />

        <span className="font-bold">Decorations:</span>
        <br />

        All decorations must be approved by <span className="font-bold">WosAm Arena</span> management no later than two (2) weeks prior to the contracted event. The following may NOT be applied to the physical structure of the venue: <span className="font-bold">tape or any type of adhesion strips, tacks, glue, nails and/or staples.</span> Decorations may NOT be hung from chandeliers or any fixtures or columns. In the event of non-compliance with this rule and at the discretion of management, the Renter will forfeit all or a portion of the security deposit or be assessed additional fees. Foil confetti is not allowed on tables on inside of display items (i.e. balloons).
        <br />

        Open flames are not permitted indoors or outdoors of the facility. All candle centerpieces must have a hurricane glass globe covering and a sturdy, nonflammable base. Votive candles and/or burning of incense are strictly prohibited. At the discretion of <span className="font-bold">WosAm Arena'</span> management, Renter can will forfeit all or a portion of the security deposit for wax spillage.
        <br />

        Smoke machines and pyrotechnics are strictly prohibited and if violated the client assumes all responsibility for damages including fire department fees, HVAC repairs, and building damages.
        <br />

        Periodically, <span className="font-bold">WosAm Arena</span> will display seasonal decorations provided by the management. These decorations may NOT be removed during contracted events.
        <br />

        The removal or rearrangement of the venue’s decorative furniture, fixtures or other equipment is strictly prohibited without approval of <span className="font-bold">WosAm Arena</span> management.
        <br />

        Delivery and pick-up of rented equipment and related items for the Renter’s use must be done within the contracted rental time unless otherwise approved by <span className="font-bold">WosAm Arena</span> management.
        <br />

        Table arrangements must be submitted to <span className="font-bold">WosAm Arena</span> office thirty (30) days before the contracted event. Once table arrangements have been submitted, the Renter may make only minor changes. Management reserves the right to make changes to table arrangement plans for safety or liability reasons.
        <br /><br />

        <span className="font-bold">Rehearsals:</span>
        <br />
        
        <span className="font-bold">WosAm Arena</span> is available for rentals seven days per week and is heavily booked therefore rehearsals are not guaranteed! Please make a contingency plan (i.e., an off-site rehearsal), in case the anticipated date is not available.
        <br />
        Rehearsals must be scheduled one week in advance and are scheduled on a <span className="font-bold">"first-come, first-served"</span> basis. The duration of a rehearsal must not exceed one hour. Please plan to arrive thirty (30) minutes prior to your rehearsal time. After one hour, a fee of Ten Thousand Naira (₦10,000.00) per hour will be assessed. Invite only participants in your party. Your group will be permitted only in the area where the ceremony is to be held. Please prepare an itinerary for your rehearsal to ensure a smooth flow. Food and beverages are not permitted during rehearsals. If the hall is already set-up for an event, the set-up should NOT be disturbed or rearranged. Children must be under the supervision of an adult at all times. Running through the hall, lobby or in the parking lot is not allowed. Failure to vacate the premises in a timely manner at the conclusion of your rehearsal will result in an overtime charge of One Hundred Thousand Naira (₦100,000.00) per hour and are due upon demand.
        <br /><br />

        <span className="font-bold">Security:</span>
        <br />
        

        Renter is required to use security personnel for contracted events (e.g., discos, cabarets, fashion shows, etc.). The security agency contracted with <span className="font-bold">WosAm Arena</span> will be scheduled for selected events as necessary and will be billed at Renter's expense. The hourly rate for security is ₦35,000.00 per hour "armed" and ₦25,000.00 per hour "unarmed," with a five (5) hour minimum.
        <br /><br />

        <span className="font-bold">Damages and Liability:</span>
        <br />
        

        The Renter assumes full responsibility for any damages caused to <span className="font-bold">WosAm Arena's</span> property, equipment, or facilities during the event. The Renter will be held liable for the repair or replacement costs.
        <br />

        <span className="font-bold">WosAm Arena</span> reserves the right to additionally bill Renter as deemed necessary by management to cover cost[s] for excessive clean-up, breakage, loss or removal of property by any representative, vendor and/or guest of the Renter. All costs for unreasonable damages or missing items will be deducted from the <span className="font-bold">Renter's</span> security deposit if charged. If security deposit is waived, the client assumes full responsibility.
        <br />

        If a security deposit is charged, the full security deposit is refundable, provided there is adherence to the contract and rules and regulations.
        <br />

        The <span className="font-bold">Renter</span> agrees to indemnify and hold harmless <span className="font-bold">WosAm Arena</span> from any claims, damages, losses, or liabilities arising from the <span className="font-bold">Renter's</span> use of the event hall including but not limited to loss or damage to cars, or personal belongings.
        <br /><br />
        
        <span className="font-bold">Conduct and Compliance:</span>
        <br />

        The <span className="font-bold">Renter</span> and event attendees must adhere to <span className="font-bold">Wosam Arena's</span> rules and regulations, including those related to noise levels, decorum, safety, and any applicable laws.
        <br />

        The <span className="font-bold">Renter</span> will be responsible for the behavior of their guests and entertainers including but not limited to the possession, use and distribution of marijuana and hard drugs. <span className="font-bold">WosAm Arena</span> operates a zero tolerance policy for drugs and reserves the right to terminate the event if the Renter, entertainers or attendees engage in behavior that violates <span className="font-bold">WosAm Arena's</span> policies or poses a threat to safety. <span className="font-bold">WosAm Arena</span> may involve law enforcement if drug use is evident or there is threat to life and property resulting from the action of the guests.
        <br />

        <span className="font-bold">WosAm Arena</span> reserves the right to control the volume of all amplified sound. Musicians and other entertainers must be in proper attire, and all entertainment must be in good taste -- keeping with the image of the <span className="font-bold">WosAm Arena</span> facility.
        <br />

        The DJ and/or band must cease playing at the end of the <span className="font-bold">Renter's</span> contracted time. Equipment breakdown and clean-up must begin promptly at the end of the event. If the DJ/band, caterers and/or guests remain in the facility past the scheduled time, <span className="font-bold">Renter</span> will be charged for the additional time at the "extra" per hour rate. The minimum "extra hour' charge is one (1) hour.
        <br />

        The <span className="font-bold">Renter</span> must comply with all provisions of the law during the event and must encourage all guests to drink responsibly. <span className="font-bold">WosAm Arena</span> management reserves the right to refuse service to anyone and further, to remove unruly guests from the premises. Smoking is not permitted inside <span className="font-bold">WosAm Arena</span>. This includes rest rooms, food prep areas, hotels, cinema, and office areas.
        <br />

        Food and beverages may be consumed only within the areas covered by the rental agreement, and is prohibited in the lobby, lounge, parking lot and areas surrounding the property. All caterers must comply with the catering procedures set forth by the management of <span className="font-bold">WosAm Arena</span>, as well as all rules and regulations regarding food service and clean-up during and after the event.
        <br />

        The <span className="font-bold">Renter</span> must ensure that all guests attending events at which alcohol is served are twenty-one (21) years of age and older.
        <br />

        Vehicles are prohibited on all walkways and designated non-parking areas. Vehicle wheels will be clamped at the expense of the vehicle owner or operator. <span className="font-bold">At the close of the contracted event, Renter shall ensure that guests do not leave bottles or trash in the lobby and/or parking lots. Renter shall also ensure that there is no congregating in the parking lots. Violation of the above will result in the Renter's loss of securing deposit or additional fees.</span>
        <br /><br />

        <span className="font-bold">Miscellaneous</span>
        <br />

        It is the responsibility of the Renter to have Liability Insurance for their scheduled event. <span className="font-bold">WosAm Arena</span> will not be responsible for any loss/theft of personal property or injury of the Renter or guests.
        <br />

        <span className="font-bold">WosAm Arena</span> staff is not responsible for set-up or breakdown of private-rented equipment. Equipment may not be stored at <span className="font-bold">WosAm Arena</span>.
        <br />

        If <span className="font-bold">Renter</span> is found to not be in compliance with any part of the Rental Agreement during the scheduled event, <span className="font-bold">WosAm Arena</span> management may immediately terminate said event without reimbursement to the <span className="font-bold">Renter</span>. If <span className="font-bold">Renter</span> is found to not be in conformance with any provisions within the Rental Agreement prior to the date of the scheduled event, <span className="font-bold">WosAm Arena</span> management reserves the right to disallow or cancel said event without reimbursement to the <span className="font-bold">Renter</span>, even if the nonconformance is corrected one (1) day prior to the scheduled event.
        <br /><br />

        VIOLATION OF <span className="font-bold">WOSAM ARENA</span> RULES AND REGULATIONS WILL RESULT IN LOSS OF ALL OR A PORTION OF RENTER’S SECURITY DEPOSIT. DAMAGES THAT EXCEED THE SECURITY DEPOSIT WILL BE BILLED DIRECTLY TO THE RENTER.
        <br /><br />

        <span className="font-bold">Advertising and Promotion</span>
        <br />
        
        All advertisements for events must be reviewed and approved by management of <span className="font-bold">WosAm Arena</span>
        <br /> BEFORE they are printed. A copy of all advertisements and tickets must be submitted to the <span className="font-bold">WosAm Arena</span> office no later than thirty (30) days prior to the event. All advertisements, tickets or other promotional materials must reflect the contracted start and ending time of the event.
        <br />

        Advertisements (e.g., posters, flyers, etc.) may not be displayed within a three (3) mile radius of <span className="font-bold">WosAm Arena</span>. All advertisements and/or promotional materials must be taken down within two (2) day following the event.
        <br />

        <span className="font-bold">WosAm Arena</span> reserves the right to use photographs and other records of events for publicity and marketing purposes.
        <br /><br />

        <span className="font-bold">Force Majeure:</span>
        <br />
        

        Neither party shall be held liable for any failure to perform its obligations due to circumstances beyond its control, including but not limited to acts of nature, government regulations, or other unforeseen events.
        <br /><br />

        <span className="font-bold">Termination:</span>
        <br />
        

        <span className="font-bold">WosAm Arena</span>
        <br /> reserves the right to terminate the booking agreement and cancel the event if the Renter fails to comply with these terms and conditions or breaches any part of the agreement.
        <br />

        <span className="font-bold">WosAm Arena</span> reserves the right at all times to cancel the Rental Agreement. If the Agreement is cancelled by management prior to the actual use of the facility by the group or organization, management agrees to refund the entire rental fee. The rental fee will not be refunded if it becomes necessary to cancel the Agreement by closing the facility due to detrimental actions by the group or organization while using the facility.
        <br /><br />

        <span className="font-bold">Governing Law:</span>
        <br />
        

        This Agreement shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria.
        <br /><br />

        <span className="font-bold">By proceeding with the booking and reservation, the Renter acknowledges that they have read, understood, and agreed to the terms and conditions outlined in this Agreement. This Agreement constitutes the entire understanding between the parties and supersedes any prior agreements or understandings, whether written or oral. Any modifications to this Agreement must be made in writing.</span>
        <br /><br /><br />
        





        ……………………………
        <br />
        [	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ]
        <br /><br />

        ……………………………
        <br />
        Date



      </p>
    </div>
  )
}

export default Terms