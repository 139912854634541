import React, { useState, useRef, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import '../tailwindCss.css'
import emailjs from 'emailjs-com';
import { Link } from 'react-router-dom'
import { PaystackButton } from 'react-paystack';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactToPrint from 'react-to-print'
import { ReactComponent as MarkIcon } from '../assets/icons/markIcon.svg'
import wosamInvoiceLogo from '../assets/logo/wosamInvoiceLogo.png'
import { set, useForm } from 'react-hook-form';
// import { Review } from '../components'

const publicKey = "pk_live_4c9e93d0c13efeb7aa2c92f31d1f7107a8ef6ef2";

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID_EVENTBOOKING;
const USER_ID = process.env.REACT_APP_USERID;

const EventBookingForm = () => {

  const { register, handleSubmit, setValue, getValues, reset } = useForm();

  const [showModal, setShowModal] = useState(false)
  const [showInvoice, setShowInvoice] = useState(true)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [subCheck, setSubCheck] = useState()
  const [termsCheck, setTermsCheck] = useState()
  const [noOfPeople, setNoOfPeople] = useState()
  const [hall, setHall] = useState('')
  const [hearAbout, setHearAbout] = useState('')
  const [eventType, setEventType] = useState('')
  const [eventDate, setEventDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [addInfo, setAddInfo] = useState('')
  const [invoiceNumber, setInvoiceNumber] = useState()
  // const [roomTypeNo, setRoomTypeNo] = useState()

  // const [isLoading, setIsLoading] = useState(false)

  const [resData, setResData] = useState(null)
  // const [invoiceNumber, setInvoiceNumber] = useState(Math.floor((Math.random() * 999999) + 100000))
  // const [formData, setFormData] = useState(null)
  const componentRef = useRef()
  // const [data, setData] = useState([])

  let resData2;
  let resData3;
  let roomType;


  

    
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  
  const formattedToday = dd + '/' + mm + '/' + yyyy;

  let hallName;
  let totalPrice;
  let initialDeposit;
  let payableDeposit;
  let balance;
  let invoiceTotal;
  let refundable = 200000.00

  

  console.log(hall);

  if (hall == '16') {
    totalPrice = 500000.00
    initialDeposit = 0.4 * totalPrice
    payableDeposit = initialDeposit + refundable
    balance = totalPrice - initialDeposit
    invoiceTotal = totalPrice + refundable;
    roomType = 16
    hallName = 'Ball Room'
    // setValue('hallNameType', hallName);
  } else if (hall == '15') {
    totalPrice = 1500000.00
    initialDeposit = 0.4 * totalPrice
    payableDeposit = initialDeposit + refundable
    balance = totalPrice - initialDeposit
    invoiceTotal = totalPrice + refundable;
    roomType = 15
    hallName = 'Ambali Hall'
    // setValue('hallNameType', hallName);
  } else if (hall == '5') {
    totalPrice = 1700000.00
    initialDeposit = 0.4 * totalPrice
    payableDeposit = initialDeposit + refundable
    balance = totalPrice - initialDeposit
    invoiceTotal = totalPrice + refundable;
    roomType = 5
    hallName = 'Wosilat Hall'
    // setValue('hallNameType', hallName);
  }

  // console.log(roomType);


  useEffect(() => {
    // Update state here when the form data changes
    setValue('invoiceNumber', Math.floor((Math.random() * 999999) + 100000));

    // if (hall == '16') {
    //   setValue('hallNameType', 'Ball Room');
    // } else if (hall == '15') {
    //   setValue('hallNameType', 'Ambali Hall');
    // } else if (hall == '5') {
    //   setValue('hallNameType', 'Wosilat Hall');
    // } 

    
  }, [resData2, resData3]);


  // useEffect(() => {

  //   if (hall == '16') {
  //     setValue('hallName', 'Ball Room');
  //   } else if (hall == '15') {
  //     setValue('hallName', 'Ambali Hall');
  //   } else if (hall == '5') {
  //     setValue('hallName', 'Wosilat Hall');
  //   } 

  // });

  // useEffect(() => {
  //   setRoomTypeNo(roomType)

  //   setValue('roomTypeId', roomTypeNo);
  // }, [roomTypeNo])

  // console.log(roomTypeNo);
  
  const priceFormatter = (price) => {
    const formattedPrice = (price).toLocaleString('en-NG', {
      style: 'currency',
      currency: 'NGN'
    });
    
    return formattedPrice
    
  }

  const handleClose = (e) => {
    if (e.target.id === 'modalContainer') {
      setShowModal(false)
    }
  }

  const toggleModal = () => {
    setShowModal(true)
  }
  
  

  const onSubmit = async (data) => {
    console.log(data);

    // if (hall == '16') {
    //   setValue('hallName', 'Ball Room');
    // } else if (hall == '15') {
    //   setValue('hallName', 'Ambali Hall');
    // } else if (hall == '5') {
    //   setValue('hallName', 'Wosilat Hall');
    // } 
    // setIsLoading(true)

    setFirstName(data.firstName);
    setLastName(data.lastName);
    setEmail(data.email);
    setPhone(data.phone);
    setSubCheck(data.subCheck);
    setTermsCheck(data.termsCheck);
    setNoOfPeople(data.noOfPeople);
    setHall(data.hall);
    setHearAbout(data.hearAbout);
    setEventType(data.eventType);
    setEventDate(data.eventDate);
    setStartTime(data.startTime);
    setEndTime(data.endTime);
    setAddInfo(data.addInfo);
    setInvoiceNumber(data.invoiceNumber);

    console.log("Button clicked");

    try {
      
      if (!data.firstName || !data.lastName || !data.email || !data.phone || !data.noOfPeople || !data.hall || !data.hearAbout || !data.eventType || !data.eventDate || !data.startTime || !data.endTime) {
        toast.error("Please fill in all inputs");
        // setIsLoading(false)
      } else if (!data.termsCheck) {
        toast.error("Please make sure you agree to Terms and conditions");
        // setIsLoading(false)
      } else {
        const formData = {
          firstName,
          lastName,
          email,
          phone,
          subCheck,
          termsCheck,
          noOfPeople,
          hall,
          hearAbout,
          eventType,
          eventDate,
          startTime,
          endTime,
          addInfo,
          invoiceNumber,
        };
        // console.log(data.email);
        // console.log(roomType);
        // console.log(roomTypeNo);
        // console.log(data.roomTypeId);
        const raw2 = JSON.stringify({
          guest: {
            title: "Mr.",
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            email: data.email,
            sex: "Male",
            occupation: "",
            countryId: 1,
            address1: "",
            address2: "",
            city: "",
            stateId: 1
          },
          reservations: [
            {
              roomTypeId: parseInt(data.hall),
              checkInDate: data.eventDate,
              checkOutDate: data.eventDate,
              adultNo: "2",
              childNo: "1",
              arrivalTime: data.startTime,
              purpose: data.eventType,
              rate: 200000,
              additionalReq: "",
              quantity: 1
            },
          ],
          payment: {
            payTypeId: 1,
            paymethodid: "1",
            amount: totalPrice,
          }
        });



        const response3 = await fetch("https://guestapi.roomability.io/Reservation/Add", {
          method: "POST",
          headers: {
            "X-API-Key": "GST_NcikGY6CHIrkF26a44exmKoqC5YFqTHqMVOh70MQSe76",
            "content-type": "application/json",
            "accept": "application/json"
          },
          body: raw2
        });

        const response = await fetch("https://guestapi.roomability.io/Reservation/Availability", {
          method: "POST",
          headers: {
            "X-API-Key": "GST_NcikGY6CHIrkF26a44exmKoqC5YFqTHqMVOh70MQSe76",
            "content-type": "application/json",
            "accept": "application/json"
          },
          body: JSON.stringify({
            checkInDate: data.eventDate,
            checkOutDate: data.eventDate,
            adultNo: "2",
            childNo: "1",
            facilityTypeId: 2
          })
        });

        const res3 = await response3.json();
        const res = await response.json();
        // console.log(res3);
        resData2 = res;
        resData3 = res3;
        console.log(resData2);
        console.log(resData3);

        if(resData2) {
          // setIsLoading(false)
        }

        console.log(resData2.errorCode);

        if (resData2.errorCode === 0) {
          console.log("Passed stage 1");
          const hallAvaialable = resData2.types.find((item) => item.roomTypeId == data.hall)
          if (hallAvaialable) {
            setShowInvoice(false);
            // setValue('invoiceNumber', Math.floor((Math.random() * 999999) + 100000));

            emailjs
              .sendForm(SERVICE_ID, TEMPLATE_ID, "#form", USER_ID)
              .then(
                (result) => {
                  console.log(result);
                  toast.success("Invoice Sent Successfully");
                },
                (err) => {
                  toast.error("Something went wrong. Try again");
                }
              );
          } else {
            // setIsLoading(false)
            toast.error(`${hallName} is not available on the date selected. Try checking another hall`);
          } 
        } else {
          // setIsLoading(false)
          toast.error(`All Halls are unavailable on the date selected. Try checking another date`);
          
        }
      }
    } catch (err) {
      console.log(err);
      toast.error("Information cannot be retrived at the moment. Try again or Contact Support");
      // Handle errors here
    }
  };


  
  const handleError = (errors) => {
    console.log(errors);
    toast.error("Please fill in all fields");
  }

  

  const amount = payableDeposit * 100;
  const amount2 = invoiceTotal * 100
  const name = `${firstName} ${lastName}`

  const componentProps = {
    email,
    amount,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "PAY 40% DEPOSIT",
    onSuccess: () => {
      toast.success("Payment Successful");
      // formRef.current.reset();
    },
    onClose: () => {
      toast.error("Something went wrong. Try again");
      // formRef.current.reset();
    },
  };
  const componentProps2 = {
    email,
    amount: amount2,
    metadata: {
      name,
      phone,
    },
    publicKey,
    text: "MAKE FULL PAYMENT",
    onSuccess: () => {
      toast.success("Payment Successful");
      // formRef.current.reset();
    },
    onClose: () => {
      toast.error("Something went wrong. Try again");
      // formRef.current.reset();
    },
  };



  


  return (
    <>
      <Helmet>
        <title>WosAm Event Center Booking | WosAm.com</title>
      </Helmet>
      {
        showInvoice ? (
        <div className='sm:pt-36 pt-28 w-full flex flex-col items-center'>
          <h2 className="text-center sm:text-4xl text-2xl font-bold text-primary">
            EVENT CENTER BOOKING FORM
          </h2>
          <div className="sm:border border-primary rounded-lg sm:py-10 py-6 sm:my-10 my-6 sm:w-[70%] w-[100%] px-4 flex flex-col items-center">
            <h3 className="sm:text-2xl text-xl font-extrabold text-center text-primary sm:mb-10 mb-6">
              Space Booking Request
            </h3>
            <form id='form' onSubmit={handleSubmit(onSubmit, handleError)} 
              className='w-[100%] flex flex-col items-center'
            >
              <div className="sm:w-[70%] w-full">
                <h4 className="text-lg font-bold mb-6">
                  Your Contact Information
                </h4>
                <div className="flex sm:flex-row flex-col items-center gap-6 w-full mb-6">
                  <input {...register('invoiceNumber')} className='hidden' />
                  {/* <input {...register('hallNameType')} className='hidden' /> */}
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="firstName" className='font-bold'>
                      First Name
                    </label>
                    <input id='firstName' type="text" className="h-12 !bg-primary pl-4 rounded-md font-medium text-white placeholder:text-white w-full" placeholder='First Name' aria-autocomplete="none"  name='firstName' {...register("firstName")} />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="lastName" className='font-bold'>
                      Last Name
                    </label>
                    <input id='lastName' type="text" className="h-12 bg-primary pl-4 rounded-md font-medium text-white placeholder:text-white w-full" placeholder='Last Name' aria-autocomplete="none" name='lastName' {...register("lastName")} />
                  </div>
                </div>
                <div className="flex sm:flex-row flex-col items-center gap-6 w-full mb-6">
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="email" className='font-bold'>
                      Email Address
                    </label>
                    <input id='email' type="text" className="h-12 bg-primary pl-4 rounded-md font-medium text-white placeholder:text-white w-full" placeholder='Email Address' aria-autocomplete="none" name='email' {...register("email")} />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="phone" className='font-bold'>
                      Phone Number
                    </label>
                    <input id='phone' type="text" className="h-12 bg-primary pl-4 rounded-md font-medium text-white placeholder:text-white w-full" placeholder='Phone Number' aria-autocomplete="none" name='phone' {...register("phone")} />
                  </div>
                </div>
                <label htmlFor="" className='text-primary text-lg'>
                    <input type="checkbox" name='subCheck' {...register("subCheck")} />  &nbsp;Subscribe to our promotional emails
                </label>
              </div>

              <div className="sm:w-[70%] w-full mt-10">
                <h4 className="text-lg font-bold mb-6">
                  Your Event Details
                </h4>
                <div className="flex sm:flex-row flex-col items-center gap-6 w-full mb-6">
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="eventType" className='font-bold'>
                      Event Type
                    </label>
                    <select id="eventType" className="h-12 bg-primary pl-4 rounded-md font-medium text-white placeholder:text-white w-full" name='eventType' {...register("eventType")}>
                      <option  value="">Event Type & Details</option>
                      <option value="Wedding Party, Bridal Party/Shower">Wedding Party, Bridal Party/Shower</option>
                      <option value="Burial/Funeral/Remembrance">Burial/Funeral/Remembrance</option>
                      <option value="Birthday Party">Birthday Party</option>
                      <option value="Naming Ceremony">Naming Ceremony</option>
                      <option value="Special Event">Special Event</option>
                    </select>
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="eventDate" className='font-bold'>
                      Event Date
                    </label>
                    <input id='eventDate' type="date" className="h-12 bg-primary pl-4 rounded-md font-medium text-white placeholder:text-white w-full text-xl" placeholder='Event Date' name='eventDate' {...register("eventDate")} />
                  </div>
                </div>
                <div className="flex sm:flex-row flex-col items-center gap-6 w-full mb-6">
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="startTime" className='font-bold'>
                      Start Time
                    </label>
                    <input id='startTime' type="time" className="h-12 bg-primary pl-4 rounded-md font-medium text-white placeholder:text-white w-full text-xl" placeholder='Start Time' name='startTime' {...register("startTime")} />
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="endTime" className='font-bold'>
                      End Time
                    </label>
                    <input id='endTime' type="time" className="h-12 bg-primary pl-4 rounded-md font-medium text-white placeholder:text-white w-full text-xl" placeholder='End Time' name='endTime' {...register("endTime")} />
                  </div>
                </div>
                <div className="flex sm:flex-row flex-col items-center gap-6 w-full mb-6">
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="hall" className='font-bold'>
                      Select Event Space
                    </label>
                    <select id="hall" className="h-12 bg-primary pl-4 rounded-md font-medium text-white placeholder:text-white w-full" name='hall' {...register("hall")}>
                      <option value="">
                        Select Event Space
                      </option>
                      <option value="16">
                        Ball Room (80 Capacity)
                        500,000
                      </option>
                      <option value="15">
                          Ambali Hall (700-800 Capacity)
                          1,500,000
                      </option>
                      <option value="5">
                          Wosilat Hall (800-900 Capacity)
                          1,700,000
                      </option>
                    </select>
                  </div>
                  <div className="flex flex-col gap-1 w-full">
                    <label htmlFor="noOfPeople" className='font-bold'>
                      Number of People
                    </label>
                    <input id='noOfPeople' type="number" className="h-12 bg-primary pl-4 rounded-md font-medium text-white placeholder:text-white w-full" placeholder='Number of People' name='noOfPeople' {...register("noOfPeople")} />
                  </div>
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <label htmlFor="addInfo" className='font-bold'>
                    Additional Information
                  </label>
                  <textarea id="addInfo" cols="30" rows="10" className="h-36 pt-4 bg-primary pl-4 rounded-md font-medium text-white placeholder:text-white w-full resize-none mb-6" placeholder='Additional Information or Event Details' name='addInfo' {...register("addInfo")}>

                  </textarea>
                </div>
                <div className="flex flex-col gap-1 w-full">
                  <label htmlFor="hearAbout" className='font-bold'>
                    How did you hear about us?
                  </label>
                  <select id="hearAbout" className="h-12 bg-primary pl-4 rounded-md font-medium text-white placeholder:text-white w-full mb-6" name='hearAbout' {...register("hearAbout")}>
                    <option value="">
                      How did you hear about us?
                    </option>
                    <option value="Advertisement">
                      Advertisement
                    </option>
                    <option value="Instagram">
                      Instagram
                    </option>
                    <option value="Facebook">
                      Facebook
                    </option>
                    <option value="Twitter">
                      Twitter
                    </option>
                    <option value="Influencers">
                      Influencers
                    </option>
                  </select>
                </div>
                <label htmlFor="" className='text-primary text-lg'>
                    <input type="checkbox" name='termsCheck' {...register("termsCheck")} />  &nbsp;I have Read and Agreed to the <Link to='/terms-and-conditions' className='underline underline-offset-2 font-bold'>Terms & Conditions</Link>
                </label>
              </div>

              <div className="sm:w-[70%] w-full border-t-2 pt-4 mt-6">
                
                <p className="">
                  {/* <span className="text-lg font-bold mb-6 text-primary">
                    Availability
                  </span>  */}
                  Operating Hours: 7am - 8pm.
                </p>
                <p className="my-1">
                  Halls open till 8pm. After 8pm additional <span className="font-bold text-primary">N50,000</span>
                </p>
                <p className="">
                  <span className="text-lg font-bold mb-6 text-primary">
                    Decorations & Catering
                  </span> Customer’s Décor & caterers welcome
                </p>
              </div>
              <div className="sm:w-[70%] w-full flex justify-center items-center sm:gap-6 gap-4 mt-10">
                {/* <button onClick={() => handleSubmit()} className="w-full py-3 rounded-full font-bold text-primary bg-secondary text-xs sm:text-base">
                  PROCEED TO PAYMENT
                </button> */}
                {/* {isLoading ? (<button  className="w-full py-3 rounded-full font-bold text-primary bg-secondary text-xs sm:text-base hover:bg-dimSecondary">
                  Sending...
                </button>) : (<button  className="w-full py-3 rounded-full font-bold text-primary bg-secondary text-xs sm:text-base hover:bg-dimSecondary">
                  PROCEED TO PAYMENT
                </button>)} */}
                <button className="w-full py-3 rounded-full font-bold text-primary bg-secondary text-xs sm:text-base hover:bg-dimSecondary">
                  PROCEED TO PAYMENT
                </button>
                

              </div>
            </form>
            {/* <ToastContainer /> */}
            {/* <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable={false}
              pauseOnHover={false}
            /> */}
          </div>

           

        </div>

        ) : (
          <div className="sm:pt-36 pt-28 w-full flex flex-col items-center sm:px-[80px] px-4">
              <h2 className="text-center sm:text-4xl text-2xl font-bold text-primary sm:mb-6 mb-4">
                INVOICE HAS BEEN GENERATED
              </h2>
              <p className="text-center sm:text-lg sm:w-[65%]">
                Make sure to download your invoice by clicking <span className="font-bold">"Download"</span> below before proceeding to clicking  <span className="font-bold">"Make Payment"</span>, as invoice might be requested when we reach out to you after payment.
              </p>
              <div className="flex justify-center items-center sm:gap-6 gap-4 mt-6 mb-16">
                <ReactToPrint
                  trigger={() => <button className="rounded-full font-bold py-3 px-6 bg-primary text-white text-xs sm:text-base">
                    DOWNLOAD INVOICE
                  </button>}
                  content={() => componentRef.current}
                />
                <button onClick={() => setShowModal(true)} className="py-3 px-6 rounded-full font-bold text-primary bg-transparent outline outline-1 outline-primary text-xs sm:text-base">
                    MAKE PAYMENT
                </button>

                {/* <PaystackButton className="py-3 px-6 rounded-full font-bold text-primary bg-secondary text-xs sm:text-base" {...componentProps2} />
                <PaystackButton className="rounded-full font-bold py-3 px-6 bg-secondary text-black text-xs sm:text-base" {...componentProps} /> */}
            
                
              </div>
              <div 
                className="flex flex-col sm:w-[800px] w-full border border-primary sm:mx-auto"
                ref={componentRef}
              >
                <div className="px-4 py-4 flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <img src={wosamInvoiceLogo} alt="" className="" />
                    <span className="sm:text-sm text-[10px] font-semibold">WosAm Arena</span>
                  </div>
                  <span className="sm:text-xs text-[10px] font-semibold">119 Obafemi Awolowo Rd Ago Iwoye, Ogun state.</span>
                </div>
                <div className="w-full flex gap-2 items-center">
                  <div className="bg-secondary sm:h-10 h-6 w-[70%]"></div>
                  <h4 className="sm:text-2xl text-lg font-black">INVOICE</h4>
                  <div className="bg-secondary sm:h-10 h-6 w-[18%]"></div>
                </div>


                <div className="px-4 mt-6 mb-10">
                  <div className="flex justify-between">
                    <div className="flex flex-col items-start gap-2">
                      <div className="flex flex-col">
                        <span className="font-bold sm:text-sm text-[10px]">Invoice to:</span>
                        <span className="font-semibold sm:text-sm text-[10px]">{firstName} {lastName} <span className="font-normal">({email})</span></span>
                      </div>
                      <div className="flex">
                        <span className="font-bold sm:text-sm text-[10px] text-secondary">Event Space: <span className="font-bold text-black">{hallName}</span></span>
                      </div>
                      <div className="flex flex-col">
                        <span className="font-bold sm:text-sm text-[10px]">Event Date:</span>
                        <span className="font-normal sm:text-sm text-[10px]">{eventDate}</span>
                      </div>
                    </div>
                    <div className="flex flex-col items-end gap-2">
                      <div className="flex flex-col items-end">
                        <span className="font-bold sm:text-sm text-[10px]">Date Issued:</span>
                        <span className="font-normal sm:text-sm text-[10px]">{formattedToday}</span>
                      </div>
                      <div className="flex flex-col items-end">
                        <span className="font-bold sm:text-sm text-[10px]">Invoice Number:</span>
                        <span className="font-normal sm:text-sm text-[10px]">#{invoiceNumber}</span>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="flex flex-col m-4 border border-primary">
                  <div className="flex items-center justify-between text-white bg-primary px-4 h-10">
                    <div className="flex items-center gap-16 justify-start">
                      <span className="font-bold sm:text-xs text-[10px]">S/N</span>
                      <span className="font-bold sm:text-xs text-[10px]">Rental Item</span>
                    </div>
                    <div className="flex items-center justify-end">
                      <span className="font-bold sm:text-xs text-[10px]">Price</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between text-black bg-white px-4 h-[50px]">
                    <div className="flex items-center gap-16 justify-start">
                      <span className="font-semibold sm:text-sm text-[10px]">1a.</span>
                      <div className="flex flex-col">
                        <span className="font-semibold sm:text-sm text-[10px]">{hallName} Full Payment</span>
                        <span className="font-semibold text-[8px]">*100% Payment</span>
                      </div>
                    </div>
                    <div className="flex items-center justify-end">
                      <span className="font-semibold sm:text-sm text-[10px]">{priceFormatter(totalPrice)}</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between text-black bg-[#E1E1E1] px-4 h-[50px]">
                    <div className="flex items-center gap-16 justify-start">
                      <span className="font-semibold sm:text-sm text-[10px]">1b.</span>
                      <div className="flex flex-col">
                        <span className="font-semibold sm:text-sm text-[10px]">{hallName} 40% Payment (Deposit)</span>
                        <span className="font-semibold text-[8px]">*40% Deposit of the expected full payment | <span className="font-black text-[10px]">Balance: {priceFormatter(balance)}</span></span>
                      </div>
                    </div>
                    <div className="flex items-center justify-end">
                      <span className="font-semibold sm:text-sm text-[10px]">{priceFormatter(initialDeposit)}</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between text-black bg-white px-4 h-[50px]">
                    <div className="flex items-center gap-16 justify-start">
                      <span className="font-semibold sm:text-sm text-[10px]">2.</span>
                      <div className="flex flex-col">
                        <span className="font-semibold sm:text-sm text-[10px]">Refundable Caution Deposit</span>
                        <span className="font-semibold text-[8px]">*Fully refundable in 7 Days (T&C Applied)</span>
                      </div>
                    </div>
                    <div className="flex items-center justify-end">
                      <span className="font-semibold sm:text-sm text-[10px]">{priceFormatter(refundable)}</span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between text-black bg-[#E1E1E1] px-4 h-[50px]">

                  </div>
                  <div className="flex items-center justify-between text-black bg-white px-4 h-[50px]">

                  </div>
                </div>


                <div className="px-4 my-10">
                  <div className="flex justify-between">
                    <div className="flex flex-col items-start gap-4">
                      <div className="flex flex-col">
                        <span className="font-bold sm:text-sm text-[10px]">Thanks for your Interest</span>
                      </div>
                      <div className="flex flex-col gap-1">
                        <span className="font-bold sm:text-sm text-[10px]">Payment Info:</span>
                        <span className="font-semibold sm:text-xs text-[10px]">Account Number: <span className="font-normal"> 2930021620</span></span>
                        <span className="font-semibold sm:text-xs text-[10px]">Account Name: <span className="font-normal"> WosAm Arena Limited</span></span>
                        <span className="font-semibold sm:text-xs text-[10px]">Bank Name: <span className="font-normal"> Eco Bank</span></span>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="font-normal sm:text-xs text-[10px]"><span className="font-bold">Note: </span>Make Deposit Payment Within the next <br /> <span className="font-bold">24 hours</span>, as this Invoice is valid for just 24 Hours</span>
                        <span className="font-semibold sm:text-xs text-[10px]">
                          *and Additional Payment fees may apply
                        </span>
                      </div>
                      
                    </div>
                    <div className="flex flex-col items-end gap-3">
                      <div className="flex flex-col items-end gap-1">
                        <span className="font-semibold sm:text-sm text-[10px]">40% Payment (Inc. Caution Deposit)</span>
                        <span className="font-bold py-1 px-3 bg-primary text-white sm:text-sm text-[10px]">{priceFormatter(payableDeposit)}</span>
                      </div>
                      <div className="flex flex-col items-end gap-1">
                        <span className="font-bold sm:text-sm text-[10px]">(Inc. Caution Deposit) Invoice Total</span>
                        <span className="font-black py-2 sm:px-6 px-3 bg-primary text-white sm:text-2xl text-lg">{priceFormatter(invoiceTotal)}</span>
                      </div>
                      <div className="flex flex-col items-center mt-8 gap-1">
                        <span className="sm:w-[200px] w-[100px] h-[1px] bg-black"></span>
                        <span className="font-normal sm:text-sm text-[10px]">Authorized Sign</span>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="flex items-center justify-between bg-secondary py-2 px-4">
                  <div className="flex items-center gap-4">
                    <span className="sm:text-xs text-[10px] font-semibold">www.wosamarena.com</span>
                    <span className="sm:text-xs text-[10px] font-semibold">09164418000</span>
                  </div>
                  <span className="sm:text-xs text-[10px] font-semibold">Info@wosamarena.com</span>
                </div>
              </div>


            {showModal && (
            <div
              id="modalContainer"
              className="fixed inset-0 flex justify-center items-center bg-[#0000001A] backdrop-blur-2xl z-50"
              onClick={handleClose}
            >
              <div className="sm:w-[45%] w-full bg-white h-[380px] flex flex-col items-center justify-center rounded-xl sm:px-16 mx-4 px-4">
                <MarkIcon className='' />
                <h4 className="text-primary text-center font-bold mt-6 mb-10 sm:text-xl text-lg sm:w-[50%]">
                    Let us know if you'd like to pay in deposit or make full payment
                </h4>
                <div className="flex items-center gap-4 justify-center w-full">
                  <PaystackButton className="w-full py-3 rounded-full font-bold text-primary bg-secondary text-xs sm:text-base" {...componentProps2} />
                  <PaystackButton className="w-full py-3 rounded-full font-bold text-primary bg-transparent outline outline-1 outline-primary text-xs sm:text-base" {...componentProps} />
                  
                  {/* <button onClick={() => setShowInvoice(false)} className="w-full py-3 rounded-full font-bold text-primary bg-transparent outline outline-1 outline-primary text-xs sm:text-base">
                    PAY DEPOSIT
                  </button> */}
                </div>
              </div>

            </div>
          )}

          </div>
        )
      }
    </>
  )
}

export default EventBookingForm