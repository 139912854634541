import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import styles from '../../scss/overall.module.scss';
import WhiteSocial from '../../components/WhiteSocial';
import { ReactComponent as WatchTV } from '../../assets/icons/icons8_watch_tv.svg';
import { ReactComponent as Swimming } from '../../assets/icons/icons8_outdoor_swimming_pool.svg';
import { ReactComponent as Waiter } from '../../assets/icons/icons8_waiter.svg';
import { ReactComponent as Wellness } from '../../assets/icons/icons8_spa_flower_1.svg';
import { ReactComponent as More } from '../../assets/icons/icons8_ellipsis_1.svg';
import { ReactComponent as Star } from '../../assets/icons/icons8_star_1.svg';
// import standardImage from '../../assets/images/Wosam Hotel Standard suite.png';
// import deluxeImage from '../../assets/images/Wosam Hotel Deluxe suite.png';
import Review from '../../components/Review';

import { useForm } from 'react-hook-form';
import { Video } from '../../components';
import { deluxeImage, standardImage, wosamHotelImage, youtubeModal } from '../../assets';
import { features } from '../../constants';

const WosamHotels = () => {
  const { handleSubmit, reset } = useForm();
  // const navigate = useNavigate();
  const down = useRef(null);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const scrollDown = () => {
    window.scrollTo({
      top: down.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const onSubmit = data => {
    reset();
  };

  const styles2 = {
    backgroundImage: `url(${wosamHotelImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center' 
  }
  const styles3 = {
    backgroundImage: `url(${youtubeModal})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center' 
  }

  return (
    <div className={styles.wosamHotels}>
      <Helmet>
        <title>Wosam Hotels | WosAm.com</title>
      </Helmet>
      <header style={styles2}>
        <h3>- WOSAM HOTEL -</h3>
        <h1>Experience the Beauty of Class and Comfort.</h1>
        <p>WosAm Hotel has a great impact and is one of the best Hotel in Ago-Iwoye Ogun state, Nigeria. We have cozy hotels for relaxation with a comfortable ambience, we offer our guests our unique blend of global 5-star finesse and world-class comfort here in Ogun State and we are ready to serve you.</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="Check-In Date">Check-In Date</label>
            <div className={styles.calendarContainer}>
              <input type="datetime-local" name="Check-In Date" id="" placeholder='Select Check-In Date' />
              {/* <Calendar className={styles.calendarIcon} /> */}
            </div>
          </div>
          <div>
            <label htmlFor="Check-Out Date">Check-Out Date</label>
            <div className={styles.calendarContainer}>
              <input type="datetime-local" name="Check-Out Date" id="" placeholder='Select Check-Out Date' />
              {/* <Calendar className={styles.calendarIcon} /> */}
            </div>
          </div>
          <button onClick={scrollDown}>Check Availability</button>
        </form>
        <WhiteSocial />
      </header>

      <div className={styles.subHeader}>
        <h3>- WELCOME TO WOSAM HOTEL -</h3>
        <h2>Where your comfortability is our priority</h2>
        <div className={styles.features}>
          {
            features.map(item => (
              <div key={item.id} className={styles.feature}>
                <img src={item.featureicon} alt="" className="" />
                <p>{item.featureName}</p>
              </div>
            ))
          }
        </div>
      </div>

      <div style={styles3} className={styles.video} onClick={handleClickOpen}></div>

      <div className={styles.ourRooms} ref={down}>
        <h2>Our Rooms</h2>
        <div className={styles.rooms}>
          <div className={styles.room}>
            <img src={standardImage} alt="" />
            <div className={styles.contents}>
              <div className={styles.star}>
                <Star />
                <Star />
                <Star />
                <Star />
                <Star />
              </div>
              <h4>NGN 35,000 <span>per day night</span></h4>
              <h3>BASIC STANDARD</h3>
              <div className={styles.buttonDiv}>
                {/* <button className={styles.primaryBtn}>View Details</button> */}
                <a className={styles.secondaryBtn} href='https://guest.reservations.ng/WOSAMARENAAGOIWOYEOGUNSTATENIGER/step1' target='_blank'>Book Now</a>
              </div>
            </div>
          </div>
          <div className={styles.room}>
            <img src={deluxeImage} alt="" />
            <div className={styles.contents}>
              <div className={styles.star}>
                <Star />
                <Star />
                <Star />
                <Star />
                <Star />
              </div>
              <h4>NGN 40,000 <span>per day night</span></h4>
              <h3>BASIC DELUXE</h3>
              <div className={styles.buttonDiv}>
                {/* <button className={styles.primaryBtn}>View Details</button> */}
                <a className={styles.secondaryBtn} href='https://guest.reservations.ng/WOSAMARENAAGOIWOYEOGUNSTATENIGER/step1' target='_blank'>Book Now</a>
              </div>
            </div>
          </div>
          <div className={styles.room}>
            <img src={standardImage} alt="" />
            <div className={styles.contents}>
              <div className={styles.star}>
                <Star />
                <Star />
                <Star />
                <Star />
                <Star />
              </div>
              <h4>NGN 50,000 <span>per day night</span></h4>
              <h3>LUXURY REGULAR</h3>
              <div className={styles.buttonDiv}>
                {/* <button className={styles.primaryBtn}>View Details</button> */}
                <a className={styles.secondaryBtn} href='https://guest.reservations.ng/WOSAMARENAAGOIWOYEOGUNSTATENIGER/step1' target='_blank'>Book Now</a>
              </div>
            </div>
          </div>
          <div className={styles.room}>
            <img src={deluxeImage} alt="" />
            <div className={styles.contents}>
              <div className={styles.star}>
                <Star />
                <Star />
                <Star />
                <Star />
                <Star />
              </div>
              <h4>NGN 55,000 <span>per day night</span></h4>
              <h3>LUXURY STANDARD</h3>
              <div className={styles.buttonDiv}>
                {/* <button className={styles.primaryBtn}>View Details</button> */}
                <a className={styles.secondaryBtn} href='https://guest.reservations.ng/WOSAMARENAAGOIWOYEOGUNSTATENIGER/step1' target='_blank'>Book Now</a>
              </div>
            </div>
          </div>
          <div className={styles.room}>
            <img src={standardImage} alt="" />
            <div className={styles.contents}>
              <div className={styles.star}>
                <Star />
                <Star />
                <Star />
                <Star />
                <Star />
              </div>
              <h4>NGN 60,000 <span>per day night</span></h4>
              <h3>LUXURY DELUXE</h3>
              <div className={styles.buttonDiv}>
                {/* <button className={styles.primaryBtn}>View Details</button> */}
                <a className={styles.secondaryBtn} href='https://guest.reservations.ng/WOSAMARENAAGOIWOYEOGUNSTATENIGER/step1' target='_blank'>Book Now</a>
              </div>
            </div>
          </div>
          
        </div>
      </div>

      {/* Youtube Modal */}
      <Video open={open} handleClose={handleClose} />

      <Review />

    </div>
  );
};

export default WosamHotels;