import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import styles from '../../scss/overall.module.scss';
import WhiteSocial from '../../components/WhiteSocial';
import { ReactComponent as WatchTV } from '../../assets/icons/icons8_watch_tv.svg';
import { ReactComponent as Swimming } from '../../assets/icons/icons8_outdoor_swimming_pool.svg';
import { ReactComponent as Waiter } from '../../assets/icons/icons8_waiter.svg';
import { ReactComponent as Gym } from '../../assets/icons/icons8_gym.svg';
import { ReactComponent as More } from '../../assets/icons/icons8_ellipsis_1.svg';
import { ReactComponent as Star } from '../../assets/icons/icons8_star_1.svg';
import thirdImage from '../../assets/images/thirdImage.jpg';
// import threeBedroomBg from '../../assets/images/threeBedroomBg.png';
import Review from '../../components/Review';

import { useForm } from 'react-hook-form';
import { threeBedroomBg, twoBedroomBg } from '../../assets';

const WosamApartments = () => {
  const { handleSubmit, reset } = useForm();
  const navigate = useNavigate();
  const down = useRef(null);

  const scrollDown = () => {
    window.scrollTo({
      top: down.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const onSubmit = data => {
    reset();
  };

  return (
    <div className={styles.wosamApartments}>
      <Helmet>
        <title>Wosam Apartments | WosAm.com</title>
      </Helmet>
      <header>
        <h3>- WOSAM APARTMENTS -</h3>
        <h1>Your Peace of Mind is our Utmost Priority.</h1>
        <p>Still looking for a shortlet apartment in Ago-Iwoye, Ogun State, similar to your home where you may host your house parties or just stay privately along with your circle of relatives and business enterprise? Stress no more! At WosAm apartment we provide 2 & 3 bedroom Apartments just for you and yours, for the quantity of days you want with good utility and neat environment all here in Ago-Iwoye.</p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="Check-In Date">Check-In Date</label>
            <div className={styles.calendarContainer}>
              <input type="datetime-local" name="Check-In Date" id="" placeholder='Select Check-In Date' />
              {/* <Calendar className={styles.calendarIcon} /> */}
            </div>
          </div>
          <div>
            <label htmlFor="Check-Out Date">Check-Out Date</label>
            <div className={styles.calendarContainer}>
              <input type="datetime-local" name="Check-Out Date" id="" placeholder='Select Check-Out Date' />
              {/* <Calendar className={styles.calendarIcon} /> */}
            </div>
          </div>
          <button onClick={scrollDown}>Check Availability</button>
        </form>
        <WhiteSocial />
      </header>

      <div className={styles.subHeader}>
        <h3>- WELCOME TO WOSAM APARTMENTS -</h3>
        <h2>Where comfort and class resides.</h2>
        <div className={styles.features}>
          <div className={styles.feature}>
            <WatchTV />
            <p>Modern Rooms</p>
          </div>
          <div className={styles.feature}>
            <Waiter />
            <p>Breakfast Meals</p>
          </div>
          <div className={styles.feature}>
            <Gym />
            <p>Gym</p>
          </div>
          <div className={styles.feature}>
            <Swimming />
            <p>Swimming Pools</p>
          </div>
          <div className={styles.feature}>
            <More className={styles.moreIcon} />
            <p>& Much More</p>
          </div>
        </div>
      </div>

      <div className={styles.ourRooms} ref={down}>
        <h2>Our Apartments</h2>
        <div className={styles.rooms}>
          <div className={styles.room}>
            <img src={threeBedroomBg} alt="" />
            <div className={styles.contents}>
              <div className={styles.star}>
                <Star />
                <Star />
                <Star />
                <Star />
                <Star />
              </div>
              <h4>NGN 100,000 <span>per day night</span></h4>
              <h3>EXECUTIVE APARTMENT (3 BEDROOMS)</h3>
              <div className={styles.buttonDiv}>
                {/* <button className={styles.primaryBtn}>View Details</button> */}
                <a className={styles.secondaryBtn} href='https://guest.reservations.ng/WOSAMARENAAGOIWOYEOGUNSTATENIGER/step1' target='_blank'>Book Now</a>
              </div>
            </div>
          </div>
          <div className={styles.room}>
            <img src={twoBedroomBg} alt="" />
            <div className={styles.contents}>
              <div className={styles.star}>
                <Star />
                <Star />
                <Star />
                <Star />
                <Star />
              </div>
              <h4>NGN 100,000 <span>per day night</span></h4>
              <h3>LUXURY SUITE (2 BEDROOMS)</h3>
              <div className={styles.buttonDiv}>
                {/* <button className={styles.primaryBtn}>View Details</button> */}
                <a className={styles.secondaryBtn} href='https://guest.reservations.ng/WOSAMARENAAGOIWOYEOGUNSTATENIGER/step1' target='_blank'>Book Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Review />

    </div>
  );
};

export default WosamApartments;