import React from 'react';
import { Helmet } from 'react-helmet';
import styles from '../../scss/overall.module.scss';
import WhiteSocial from '../../components/WhiteSocial';
import Review from '../../components/Review';
import { cinemaImage } from '../../assets';

const WosamCinema = () => {
  const styles2 = {
    backgroundImage: `url(${cinemaImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center' 
  }
  return (
    <div className={styles.cinemaPage}>
      <Helmet>
        <title>Wosam Cinema | WosAm.com</title>
      </Helmet>
      <header style={styles2}>
        <h4>- WOSAM CINEMA -</h4>
        <h1>Blockbuster movies
          to keep you entertained.</h1>
        <p>WosAm Cinema Is a unique film exhibition place with a style that incorporates diverse features including cutting-edge films luxurious dines in cinema with the best views to see a movie and professional operations management</p>
        <a href="https://wosamcinemas.com" target={`_blank`}>
          <button>Go to Cinema</button>
        </a>
        <WhiteSocial />
      </header>
      <Review />
    </div>
  );
};

export default WosamCinema;