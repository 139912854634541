import React from 'react';
import styles from '../scss/overall.module.scss';

import { review } from '../constants';
import ReviewCarousel from './carousels/ReviewCarousel';
import { CarouselItem } from './carousels/ReviewCarousel';

const Review = () => {
  return (
    <div className={styles.review}>
      <ReviewCarousel>
        {review.map(item => (
          <CarouselItem name={item.name} text={item.text} img={item.img} key={item.name} bgImg={item.bgImg} styles={styles} />
        ))}
      </ReviewCarousel>
    </div>
  );
};

export default Review;
