import React from 'react';
import { Helmet } from 'react-helmet';
import styles from '../../scss/overall.module.scss';
import slide3 from '../../assets/images/slide3.jpg';
import slide4 from '../../assets/images/slide4.png';
import slide5 from '../../assets/images/slide5.png';
import slide6 from '../../assets/images/slide6.png';
import { useNavigate } from 'react-router-dom';
import { slideImg } from '../../constants';
import SlideshowCarousel, { CarouselItem } from '../../components/carousels/SlideshowCarousel';

const WeddingPage = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.weddingEvent}>
      <Helmet>
        <title>Wedding Events | WosAm.com</title>
      </Helmet>
      <div className={styles.burna}>
        <h2>Wedding</h2>
        <p>
          Whether you’re saying “I Do” for the first time or renewing your vows of commitment to each other, WosAm Arena offers the perfect location for a wedding ceremony, wedding reception, rehearsal dinner, or bridal shower. Your wedding celebration is a milestone never to be forgotten – share it here with family and friends as they watch you begin your new life together.
          <br />
          <br />
          Our team specializes in Promoting Memorable Moments that are unique and tailored as we transform our venue into your romantic vision for your special day. We’ll work seamlessly with your wedding planner to ensure that you feel like a star on the best day of your life.
        </p>
        <button>Book our Hall</button>
      </div>
      <div className={styles.subHeader}>
        <span></span>
        <h2>Diverse Cultural Weddings</h2>
        <p>
          We specialize in Yoruba, Igbo, Hausa, English and Western weddings, understanding the wants and needs of these cultures and providing the necessary requirements to meet your very specific needs. We can accommodate small weddings of 100 to large weddings of 2,500 guests.
          <br /><br />
          Celebrate your union with your family and community, as we transform our venue into the wedding of your dreams!
        </p>
      </div>
      <div className={styles.weddingCards}>
        <div className={styles.weddingCard}>
          <img src={slide3} alt="Bridal" />
          <h3>Bridal Shower</h3>
          <p>Before brides-to-be say “I Do” and go from Miss to Mrs., you can have a girls-only celebration at WosAm Arena! Have a fun-filled bridal shower that can include everything that you’d like – custom cocktails, catering of your choice, gift-giving, games, and of course—dancing!</p>
        </div>
        <div className={styles.weddingCard}>
          <img src={slide4} alt="Bridal" />
          <h3>Wedding Ceremonies</h3>
          <p>Couples can save money and eliminate the need for guests to travel across town between bridal events by hosting both the wedding ceremony and reception at WosAm Arena. Our beautiful venue is customizable and perfect for exchanging your vows with your forever.</p>
        </div>
        <div className={styles.weddingCard}>
          <img src={slide5} alt="Bridal" />
          <h3>Wedding Receptions</h3>
          <p>WosAm Arena is the ultimate venue for your wedding reception. Our beautifully appointed spaces offer versatility to bring your vision to reality. From intimate gatherings of 100 to large celebrations of 2,500 seated guests, we can help your wedding dreams come true.</p>
        </div>
        <div className={styles.weddingCard}>
          <img src={slide6} alt="Bridal" />
          <h3>Vows Renewal</h3>
          <p>WosAm Arena is the ultimate venue for your wedding reception. Our beautifully appointed spaces offer versatility to bring your vision to reality. From intimate gatherings of 100 to large celebrations of 2,500 seated guests, we can help your wedding dreams come true.</p>
        </div>
      </div>
      <div className={styles.slideshow}>
        <h3>Slideshow</h3>
        {/* <div className={styles.slides}>
          <div className={styles.slide1}></div>
          <div className={styles.slide2}></div>
          <div className={styles.slide3}></div>
        </div> */}
        <div className={styles.slidesSmallscreen}>
          <SlideshowCarousel>
            {slideImg.map((item, index) => (
              <CarouselItem img={item.img} key={item.img + index} />
            ))}
          </SlideshowCarousel>
        </div>
        <button onClick={() => navigate('/media/our-gallery')}>View Gallery</button>
      </div>
    </div>
  );
};

export default WeddingPage;