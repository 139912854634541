import React from 'react';
import { Helmet } from 'react-helmet';
import styles from '../../scss/overall.module.scss';
import { ourTeamImage } from '../../assets';
import { useNavigate } from 'react-router-dom'

// import murphy from '../../assets/images/Murphy Adekoya.png';
// import bd1 from '../../assets/images/bd1.png';
// import bd2 from '../../assets/images/bd2.png';
// import bd3 from '../../assets/images/bd3.png';
// import bd4 from '../../assets/images/bd4.png';
// import bd5 from '../../assets/images/bd5.png';
// import bd6 from '../../assets/images/bd6.png';

const OurTeam = () => {

  const navigate = useNavigate()

  const styles2 = {
    backgroundImage: `url(${ourTeamImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center' 
  }

  return (
    <div className={styles.ourTeamPage}>
      <Helmet>
        <title>Our Team | WosAm.com</title>
      </Helmet>
      <div style={styles2} className={styles.ourTeam}>
        <h2>Our Team</h2>
        <p>WosAm Arena, we delight ourselves as a unique arena with multipurpose services that strives to exceed your expectations with remarkable services. We are committed to Promoting Memorable reports to all our guests. And this can't be possible without the help of our team.</p>
        <button 
          className='bg-secondary text-primary py-4 px-8 rounded-full text-lg mt-6 font-bold'
          onClick={() => navigate('/career')}
        >
            Career Page
          </button>
      </div>
      {/* <div className={styles.board}>
        <div className={styles.title}>
          <span></span>
          <h2>Board of Directors</h2>
          <h3>Meet the Directors</h3>
          <div className={styles.directors}>
            <div className={styles.director}>
              <img src={murphy} alt="the chairman" />
              <h4>Mufu Adekoya</h4>
              <h5>Chairman</h5>
            </div>
            {/* <div className={styles.director}>
              <img src={chairman2} alt="the chairman" />
              <h4>Mufu Adekoya</h4>
              <h5>Chairman</h5>
            </div>
            <div className={styles.director}>
              <img src={chairman3} alt="the chairman" />
              <h4>Mufu Adekoya</h4>
              <h5>Chairman</h5>
            </div> */}
          {/* </div>
        </div>
      </div> */} 

      {/* <div className={styles.board2}>
        <div className={styles.title}>
          <span></span>
          <h2>Management Team</h2>
          <h3>Meet the Team</h3>
          <div className={styles.directors}>
            <div className={styles.director}>
              <img src={bd1} alt="the chairman" />
              <h4>Mufu Adekoya</h4>
              <h5>Chairman</h5>
            </div>
            <div className={styles.director}>
              <img src={bd2} alt="the chairman" />
              <h4>Mufu Adekoya</h4>
              <h5>Chairman</h5>
            </div>
            <div className={styles.director}>
              <img src={bd3} alt="the chairman" />
              <h4>Mufu Adekoya</h4>
              <h5>Chairman</h5>
            </div>
            <div className={styles.director}>
              <img src={bd4} alt="the chairman" />
              <h4>Mufu Adekoya</h4>
              <h5>Chairman</h5>
            </div>
            <div className={styles.director}>
              <img src={bd5} alt="the chairman" />
              <h4>Mufu Adekoya</h4>
              <h5>Chairman</h5>
            </div>
            <div className={styles.director}>
              <img src={bd6} alt="the chairman" />
              <h4>Mufu Adekoya</h4>
              <h5>Chairman</h5>
            </div>
          </div>
        </div>
      </div> */}

    </div>
  );
};

export default OurTeam;