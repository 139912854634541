import React from 'react';
import { Helmet } from 'react-helmet';
import styles from '../../scss/overall.module.scss';
import { aboutWosamImage, slide3, slide4, slide5, slide6 } from '../../assets';
import { useNavigate } from 'react-router-dom'


const Overview = () => {

  const navigate = useNavigate()


  const styles2 = {
    backgroundImage: `url(${aboutWosamImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center' 
  }

  return (
    <div className={styles.aboutUsPage}>
      <Helmet>
        <title>Overview | WosAm.com</title>
      </Helmet>
      <div style={styles2} className={styles.aboutUs}>
        <h2>Overview</h2>
        <p className=''>At WosAm Arena, we pride ourselves as a beautiful multipurpose event center that strives to exceed your expectations with outstanding service. We are committed to Promoting Memorable Moments for life’s most important events.</p>
        <button
          onClick={() => navigate('/contact-us')}
        >
          Contact Us
        </button>
        
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <span></span>
          <h3>Who we are?</h3>
          <p>Know more about us</p>
        </div>
        <p className='mb-10'>
          WosAm Arena is truly a unique Place where we help create an unforgettable experience for you. Offering 42,000 square feet of rental space, we can easily accommodate 100 to 4,000 guests for small, intimate affairs to grand galas to multi-day, large-scale events. from corporate events to special occasions, and social celebrations, our venue is completely customizable, we also have the coolest spot in Ago iwoye, Ogun state, we encompass many features as well as services rendered Which are, WosAm Lounge, WosAm Café, WosAm Cinema, WosAm Conference hall, WosAm Night clubs, WosAm short lets apartments, we also have our car rental services WosAmonthemove to take you anywhere around Ago iwoye. Whatever you dream of, we can make happen.
          <br /><br />
          WosAm Arena is the perfect place to host your Wedding ceremony, Engagement, Reception, Burial, Birthday Party, Class Reunion, Christmas Party, Bridal Shower, Rehearsal Dinner, Cocktail Receptions, Fundraisers & Galas, Holiday Parties, Sports Banquet, Corporate meetings, Concerts, Clubbing, Shows and much more! We offer unique and fully functional Services to choose from. We are centrally located on the main road of Ago-Iwoye a few minutes away from Ijebu Ode, Ibadan, Abeokuta, and Lagos, so no need to fret, you are in an amazing arena.
          <br /><br />
          You and your guests will be awed by our customized, antique mahogany bar and each of our event spaces has a dedicated, large dance floor, with LED lighting above, where your guests can party the night away. A full-scale, commercial kitchen, abattoir is available for use by your selected caterer, and abundant, complimentary on-site parking with sitting area for drivers/chauffeur provides your guests convenient access to our venue.
        </p>
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <span></span>
          <h3>Why WosAm?</h3>
          <p className='mb-10'>Question should be why not WosAm?</p>
        </div>
      </div>
      <div className={styles.weddingEvent}>
        <div className={styles.weddingCards}>
          <div className={styles.weddingCard}>
            <img src={slide3} alt="Bridal" />
            <h3>Culinary Skills</h3>
            <p>Before brides-to-be say “I Do” and go from Miss to Mrs., you can have a girls-only celebration at WosAm Arena! Have a fun-filled bridal shower that can include everything that you’d like – custom cocktails, catering of your choice, gift-giving, games, and of course—dancing!</p>
          </div>
          <div className={styles.weddingCard}>
            <img src={slide4} alt="Bridal" />
            <h3>Maintenance Culture</h3>
            <p>Couples can save money and eliminate the need for guests to travel across town between bridal events by hosting both the wedding ceremony and reception at WosAm Arena. Our beautiful venue is customizable and perfect for exchanging your vows with your forever.</p>
          </div>
          <div className={styles.weddingCard}>
            <img src={slide5} alt="Bridal" />
            <h3>Amazing Team</h3>
            <p>WosAm Arena is the ultimate venue for your wedding reception. Our beautifully appointed spaces offer versatility to bring your vision to reality. From intimate gatherings of 100 to large celebrations of 2,500 seated guests, we can help your wedding dreams come true.</p>
          </div>
          <div className={styles.weddingCard}>
            <img src={slide6} alt="Bridal" />
            <h3>Top tier Wait Staffs</h3>
            <p>WosAm Arena is the ultimate venue for your wedding reception. Our beautifully appointed spaces offer versatility to bring your vision to reality. From intimate gatherings of 100 to large celebrations of 2,500 seated guests, we can help your wedding dreams come true.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
