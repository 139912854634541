import React from 'react';
import styles from '../scss/overall.module.scss';
import { ReactComponent as Youtube } from '../assets/icons/homeYouTube.svg';
import { ReactComponent as Instagram } from '../assets/icons/homeInstagram.svg';
import { ReactComponent as Facebook } from '../assets/icons/homeFacebook.svg';
import { ReactComponent as Twitter } from '../assets/icons/homeTwitter.svg';

const Social = () => {
  return (
    <div className={styles.socials}>
      <a href='https://www.youtube.com/channel/UCV0oXB6NVvCr1jjCsejSntw'>
        <Youtube className={styles.social} />
      </a>
      <a href='https://www.instagram.com/wosamarena/' target='blank' >
        <Instagram className={styles.social} />
      </a>
      <a href='https://www.facebook.com/wosam.arena.7/' target='blank' >
        <Facebook className={styles.social} />
      </a>
      <a href='https://www.twitter.com/wosamarena/' target='blank' >
        <Twitter className={styles.social} />
      </a>
    </div>
  );
};

export default Social;