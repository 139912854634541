import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import ReactPortal from '../../helpers/ReactPortal';

import './video.css';

const Video = ({ handleClose, open }) => {
  const nodeRef = useRef(null);

  return (
    <>
      <ReactPortal wrapperId='react-portal-modal-container'>
        <CSSTransition
          in={open}
          unmountOnExit
          timeout={{ enter: 0, exit: 300 }}
          nodeRef={nodeRef}
        >
          <div className='modal' style={{ zIndex: '50' }} onClick={handleClose} ref={nodeRef}>
            <div className="modal-content bawse__video" onClick={e => e.stopPropagation()}>
              <iframe
                src="https://www.youtube.com/embed/5qa4RI_sxKo?controls=0 &autoplay=1"
                title="Bawse"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </div>
          </div>
        </CSSTransition>
      </ReactPortal>
    </>
  );
};

export default Video;
