import React, { useRef } from 'react'
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import styles from '../scss/overall.module.scss';
import logo from '../assets/logo/Wosam-1.png';
import map from '../../src/assets/images/map.png';
import { AiFillYoutube as Youtube, AiFillTwitterSquare as Twitter, AiFillInstagram as Instagram, AiFillFacebook as Facebook } from 'react-icons/ai';

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID_CALLBACK;
const USER_ID = process.env.REACT_APP_USERID;

const Footer = () => {
    const form = useRef()
    const { register, handleSubmit, reset } = useForm();

    const onSubmit = (e) => {
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID)
            .then(
                (result) => {
                    console.log(result)
                    toast.success("Message Sent Successfully");
                },
                (err) => {
                    toast.error("Something went wrong. Try again");
                    // console.log(err.text);
                }
            );
        reset();
    };

    const handleError = (errors) => console.log(errors);

    return (
        <footer>
            <div className={styles.callBack}>
                <h3>QUICK CALL BACK</h3>
                <p>From our Event Planning team??</p>
                <div>
                    <form id='form' ref={form} onSubmit={handleSubmit(onSubmit, handleError)}>
                        <input type="text" placeholder='Name' name='name' {...register("name")} />
                        <input type="number" placeholder='Phone Number' name='phone' {...register("phone")} />
                        <button type="submit">Send</button>
                    </form>
                    <ToastContainer
                        position="bottom-left"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable={false}
                        pauseOnHover={false}
                    />;
                    {/* <span><Ok className={styles.icon} />Message Sent Successfully</span> */}
                </div>
            </div>

            <div className={styles.address}>
                <img src={logo} className={styles.logo} alt='img' />
                <div className={styles.aboutWosam}>
                    <div className={styles.wosamAddress}>
                        <div className={styles.mainAddress}>
                            <h4>Address:</h4>
                            <p>119 Obafemi Awolowo Road,
                                Ago-Iwoye Ogun State.</p>
                        </div>
                        <div className={styles.mainAddress}>
                            <h4>Email:</h4>
                            <p>info@wosamarena.com</p>
                        </div>
                        <div className={styles.mainAddress}>
                            <h4>Phone:</h4>
                            <p>09164418000, 09164419000,
                                09153253750</p>
                        </div>
                    </div>

                    <div className={styles.siteNavigations}>
                        <h4>SITE NAVIGATIONS</h4>
                        <div className={styles.navigations}>
                            <Link to='/'>Home</Link>
                            <Link to='/events/corporate-events'>Coporate</Link>
                            <Link to='/facilities/wosamonthemove'>Wosamonthemove</Link>
                            <Link to='/media/our-gallery'>Gallery</Link>
                            <Link to='/about/overview'>Overview</Link>
                            <Link to='/facilities/wosam-hotels'>Wosam Hotel</Link>
                            <Link to='/facilities/wosam-night-club'>Wosam Night Club</Link>
                            <Link to='/media/latest-news'>Latest News</Link>
                            <Link to='/about/our-team'>Our Team</Link>
                            <Link to='/facilities/wosam-apartments'>Wosam Apartments</Link>
                            <Link to='/facilities/wosam-lounge'>Wosam Lounge</Link>
                            <Link to='/media/newsletter'>Newsletter</Link>
                            <Link to='/events/wedding-events'>Weddings</Link>
                            <Link to='/facilities/wosam-event-hall'>Wosam Event Hall</Link>
                            <Link to='/facilities/wosam-cinema'>Wosam Cinema</Link>
                            <Link to='/career'>Career</Link>
                            <Link to='/events/social-events'>Socials</Link>
                            <Link to='/facilities/wosam-conference-hall'>Wosam Conference</Link>
                            <Link to='/facilities/wosam-concert-space'>Wosam Concert Space</Link>
                            <Link to='/facilities/wosam-cafe'>Wosam Cafe</Link>
                            <Link to='/contact-us'>Contact</Link>
                        </div>

                    </div>

                    <div className={styles.location}>
                        <h4>WE ARE HERE</h4>
                        <img src={map} alt="map" />
                    </div>
                </div>

            </div>

            <div className={styles.copyright}>
                <span>Copyright 2022 <strong>WosAm</strong> All Rights Reserved.</span>
                <div className={styles.sociallinks}>
                    <Youtube className={styles.socialLink} />
                    <Twitter className={styles.socialLink} />
                    <Facebook className={styles.socialLink} />
                    <Instagram className={styles.socialLink} />
                </div>
            </div>
        </footer>
    );
};

export default Footer;